import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";

import { Grid, Typography, Box } from "@mui/material";
import EnergyFlow from "../flow/EnergyFlow";
import WallboxCard from "../wallbox/WallboxCard";
import HeatpumpCard from "../heatpump/HeatpumpCard";
import { checkUrlAccessRights } from "../../navigation";
import { time2String } from "../../../react_utils/utils";

export default function PageInstructions({ location, session }) {
  const [wallboxSetPoint, setWallboxSetPoint] = useState({
    locked: true,
    surplus_charging: false,
    hybrid_charging_current: null,
  });
  const [heatpumpSetPoint, setHeatpumpSetPoint] = useState({
    sg_ready: true,
    _heatpump: { sg_ready_start_threshold: null, sg_ready_end_threshold: null },
  });

  const editHeatpumpTime = (heatpump, lower, higher) => {
    setHeatpumpSetPoint((s) => ({
      ...s,
      _heatpump: {
        ...s._heatpump,
        sg_ready_start_threshold: time2String(lower),
        sg_ready_end_threshold: time2String(higher),
      },
    }));
  };

  return (
    <Fragment>
      <Grid container justify="center">
        {checkUrlAccessRights(session.user, "flowhistorie") && [
          <Grid
            key="flow-instructions"
            xs={12}
            item
            justifyContent="center"
            alignItems="center"
            marginY={3}
          >
            <Typography align="center" fontSize="h6.fontSize" color="secondary">
              Übersicht
            </Typography>
            <Grid container justify="center">
              <Grid xs={12} lg={8} item>
                <Typography color="secondary" fontWeight="bold">
                  Flow
                </Typography>
                <Typography>
                  Auf der Startseite befindet sich eine Übersicht über Ihre
                  gesamten Anlage. Sie sehen die aktuellen Energieflüsse der
                  Anlage (bei Vorhandensein entsprechender Komponenten):{" "}
                </Typography>
                <Typography component="ul">
                  <Typography component="li">
                    Die Produktion Ihrer PV-Anlage
                  </Typography>
                  <Typography component="li">
                    Das Entladen / Laden der Batterie (sowie den
                    Batterieladezustand)
                  </Typography>
                  <Typography component="li">
                    Den Netzbezug / die Einspeisung
                  </Typography>
                  <Typography component="li">
                    Den Bezug Ihrer Wallbox
                  </Typography>
                  <Typography component="li">
                    Die Last Ihres Haushaltes
                  </Typography>
                </Typography>
                <Box sx={{ height: 10 }} />
                {location.impulsclient_set.length > 0 && (
                  <Typography>
                    Dieser Graph wird regelmäßig aktualisiert und zeigt den
                    Ist-Zustand an. Ist ein Energieimpuls an all Ihre
                    Wechselrichter angeschlossen, werden die Daten in kurzen
                    Zeitabständen von Ihrem Wechselrichter ausgelesen und hier
                    anzeigt. Sind nicht alle Wechselrichter an Ihren
                    Energieimpuls angeschlossen und werden stattdessen
                    zusätzlich Daten von Growatt genutzt, werden diese lediglich
                    im 5-Minuten-Takt von Growatt aktualisiert. Durch die
                    unterschiedliche Auflösung der Daten, kann es hier zeitweise
                    zu konträren Zuständen kommen. Diese behindern jedoch nicht
                    die Steuerung Ihrer Komponenten durch den Energieimpuls, wie
                    bspw. die Überschussladung, da diese über die Einspeisung
                    gesteuert wird, die vom Energieimpuls ausgelesen wird.
                  </Typography>
                )}
              </Grid>
              <Grid xs={12} lg={4} item>
                <EnergyFlow
                  flowheight={{ xs: "300px", lg: "400px" }}
                  flowwidth={"100%"}
                  flowData={{
                    state: { battery_soc: 94 },
                    flow: {
                      pv: 18,
                      wallbox: 11,
                      household: 4,
                      to_battery: 3,
                      to_grid: 0,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>,

          location && location.wallbox_set.length > 0 && (
            <Grid
              key="wallbox-control-instructions"
              xs={12}
              sm={12}
              md={12}
              item
              justifyContent="center"
              alignItems="center"
              marginY={3}
            >
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid xs={12} lg={9} item>
                  <Typography color="secondary" fontWeight="bold">
                    Wallbox-Steuerung
                  </Typography>
                  <Typography>
                    Unten auf der Startseite kann Ihre Wallbox gesteuert werden:{" "}
                  </Typography>
                  <Box sx={{ height: 10 }} />
                  <Typography color="secondary">
                    Sperren/Entsperren:{" "}
                  </Typography>
                  <Typography>
                    Wenn Sie auf das Schloss-Symbol klicken, können Sie Ihre
                    Wallbox sperren/entsperren. Sie können die Wallbox dauerhaft
                    entsperrt lassen (z. B. wenn diese sich in Ihrer Garage
                    befindet) oder nach dem Ladevorgang sperren, um zu
                    verhindern, dass Unbefugte Ihre Wallbox nutzen.
                  </Typography>
                  <Box sx={{ height: 10 }} />
                  <Typography color="secondary">
                    PV-Überschussladen:{" "}
                  </Typography>
                  <Typography>
                    Wenn Sie wünschen, dass Ihr E-Fahrzeug nur mit Strom aus
                    Ihrer PV-Anlage geladen wird, aktivieren Sie das
                    „PV-Überschussladen“. Beim PV-Überschussladen wird Ihre
                    Wallbox das E-Fahrzeug nur laden, wenn Ihre PV-Anlage mehr
                    Strom produziert als Sie verbrauchen. Dies ermöglicht eine
                    optimale Nutzung Ihrer Energie, da Sie lediglich Ihr Auto
                    laden, wenn Ihre PV-Anlage Strom produziert und so das Laden
                    mit vergleichsweise teurem Netzstrom verhindert wird. Die
                    Wallbox beginnt das Auto zu laden, sobald ihr Überschuss die
                    minimale Ladeenergie der Wallbox übersteigt. Die Ladeenergie
                    wird fortlaufend an Ihren Überschuss angepasst.
                  </Typography>
                  <Box sx={{ height: 10 }} />
                  <Typography color="secondary">PV-Hybridladen: </Typography>
                  <Typography>
                    Wenn Sie wünschen, dass Ihr E-Fahrzeug im hybriden Modus mit
                    der Mindestleistung aus dem Netz bzw. der Batterie und
                    zusätzlichem PV-Überschuss geladen wird, aktivieren Sie das
                    PV-Hybridladen. Die Wallbox beginnt sofort, das Auto zu
                    laden, auch wenn der PV-Überschuss die Mindestleistung nicht
                    erreicht. Die zusätzliche Ladeenergie, die nicht auch dem
                    Netz oder der Batterie kommt, wird fortlaufend an Ihren
                    Überschuss angepasst. Sie können hierbei den Ladestrom
                    zwischen 6A und 16A einstellen. Die Wallbox zieht dann immer
                    den PV-Überschuss der Anlage und ergänzt dann mit Netz- bzw.
                    Batteriestrom, um den eingestellten Ladestrom zu erreichen.
                  </Typography>
                </Grid>
                <Grid xs={12} lg={3} item>
                  <WallboxCard
                    editWallboxName={null}
                    wallboxSetPoint={wallboxSetPoint}
                    setWallboxLocked={(_, locked) =>
                      setWallboxSetPoint((s) => ({ ...s, locked }))
                    }
                    setWallboxHybridCharging={(_, hybrid_charging_current) =>
                      setWallboxSetPoint((s) => ({
                        ...s,
                        hybrid_charging_current,
                      }))
                    }
                    setWallboxSurplusCharging={(_, surplus_charging) =>
                      setWallboxSetPoint((s) => ({ ...s, surplus_charging }))
                    }
                    infoText={false}
                    session={session}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),

          location && location.heatpump_set.length > 0 && (
            <Grid
              key="heatpump-control-instructions"
              xs={12}
              sm={12}
              md={12}
              item
              justifyContent="center"
              alignItems="center"
              marginY={3}
            >
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid xs={12} lg={9} item>
                  <Typography color="secondary" fontWeight="bold">
                    Wärmepumpen-Steuerung
                  </Typography>
                  <Typography>
                    Ihre Wärmepumpe ist SG-Ready-fähig. Dies bedeutet, dass die
                    Soll-Temperatur erhöht werden kann, wenn viel Sonnenenergie
                    verfügbar ist. Zur Schonung Ihrer Wärmepumpe sollte dies
                    jedoch nicht beliebig oft erfolgen. Die Steuerung der
                    Wärmepumpe übernimmt das System für Sie basierend auf
                    Wetter- und Lastvorhersagen. Daher sehen Sie hier lediglich
                    den Status, können Ihn aber nicht manuell ändern. Bitte
                    beachten Sie, dass es ein paar Tage dauern kann, bis die
                    Soll-Temperatur der Wärmepumpe das erste Mal bei hoher
                    PV-Erzeugung erhöht wird, da zunächst ein Kalibiervorgang
                    für die Schätzung der Verbrauchswerte läuft. Zudem können
                    hier die Zeiten eingeschränkt werden, in denen SG-Ready
                    eingeschaltet wird, falls Sie dies wünschen. Stellen Sie
                    bspw. die früheste Anschaltzeit auf 9 Uhr und die späteste
                    Anschaltzeit auf 18 Uhr, wird SG-Ready nicht vor 9 bzw. nach
                    18 Uhr eingeschaltet. Beachten Sie hier, dass es sich
                    lediglich um eine Einschränkung der Einschaltzeiten handelt.
                    Wird SG-Ready um 17 Uhr eingeschaltet, kann es (bei
                    entsprechender Erzeugungs- und Verbrauchsprognose) sein,
                    dass es bis 19 Uhr eingeschaltet bleibt.
                  </Typography>
                </Grid>
                <Grid xs={12} lg={3} item>
                  <HeatpumpCard
                    editHeatpumpName={null}
                    editHeatpumpTime={editHeatpumpTime}
                    heatpumpSetPoint={heatpumpSetPoint}
                    infoText={false}
                    session={session}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),
        ]}

        {checkUrlAccessRights(session.user, "flowhistorie") && (
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            justifyContent="center"
            alignItems="center"
            marginY={3}
          >
            <Typography align="center" fontSize="h6.fontSize" color="secondary">
              Flusshistorie
            </Typography>
            <Typography>
              In der Flusshistorie sehen Sie vergangene Energieflüsse (Tages- /
              Monats- und Jahresdaten). Die Tagesansicht zeigt die Werte
              aufgeschlüsselt im 5-Minuten-Takt über den Tag. Hierbei handelt es
              sich um Momentaufnahmen zu der jeweiligen Uhrzeit und nicht um
              kumulierte Werte. Die Monats- und Jahresansicht zeigen hingegen
              die kumulierten Werte für alle Tage eines Monats bzw. alle Monate
              eines Jahres.
            </Typography>
            <Typography>
              In der Flusshistorie sind (je nach gewähltem Zeitintervall) bis zu
              vier verschiedene Graphen zu sehen:
            </Typography>
            <Typography color="secondary">Leistungsflüsse</Typography>
            <Typography>
              Dieser Graph umfasst alle gespeicherten Leistungflüsse: Die
              Einspeisung, den Netzbezug, die Batterieentladung, die Erzeugung
              sowie die Last.
            </Typography>
            <Typography color="secondary">Nutzung der Erzeugung</Typography>
            <Typography>
              Hier sehen Sie, wie viel der Erzeugung wofür genutzt wurde: Zur
              direkten Nutzung, zur Batterieaufladung sowie zur Einspeisung in
              das Netz.
            </Typography>
            <Typography color="secondary">Deckung des Verbrauchs</Typography>
            <Typography>
              In diesem Graph wird gezeigt, wodurch Ihr Verbrauch zu welchen
              Teilen gedeckt wurde: Aus dem Netz, aus eigener Erzeugung sowie
              aus dem Batteriebezug.
            </Typography>
            <Typography color="secondary">Batterie-Ladezustand</Typography>
            <Typography>
              Dieser Graph zeigt den Ladezustand der Batterie und gibt dabei
              Lade- und Entladevorgänge an.
            </Typography>
            <Typography color="secondary">Datenexport</Typography>
            <Typography>
              Unten auf der Seite der Flusshistorie können Sie die Daten für
              verschiedene Zeitintervalle als Excel-Datei herunterladen.
            </Typography>
          </Grid>
        )}

        {checkUrlAccessRights(session.user, "ladehistorie") && (
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            justifyContent="center"
            alignItems="center"
            marginY={3}
          >
            <Typography align="center" fontSize="h6.fontSize" color="secondary">
              Ladehistorie
            </Typography>
            <Typography>
              In der Ladehistorie können Sie die Ladevorgänge Ihres E-Autos
              einsehen sowie die Daten als Excel-Datei exportieren. Bitte
              beachten Sie, dass es sich bei der Lademenge lediglich um eine
              Hochrechnung handelt, falls Sie keinen separaten Zähler für die
              Wallbox haben, der an den Energieimpuls angeschlossen ist.
            </Typography>
          </Grid>
        )}

        {checkUrlAccessRights(session.user, "waermepumpe") && (
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            justifyContent="center"
            alignItems="center"
            marginY={3}
          >
            <Typography align="center" fontSize="h6.fontSize" color="secondary">
              Wärmepumpe
            </Typography>
            <Typography>
              Auf dieser Seite sehen Sie, wann in der Vergangenheit der
              PV-Einschaltbefehl für Ihre Wärmepumpe aktiv war, d.h. zu welchem
              Zeitpunkt die Soll-Termperatur bei viel verfügbarer Sonnenenergie
              erhöht wurde. Die Steuerung der Soll-Temperatur beruht auf der
              Vorhersage Ihrer Erzeugung sowie vergangenen Verbrauchswerten, die
              genutzt werden, um einen Überschuss vorherzusagen. Ist der
              vorhergesagte Überschuss über einen gewissen Zeitraum hoch genug,
              wird der PV-Einschaltbefehl aktiviert. Für eine optimale Steuerung
              Ihrere Wärmepumpe bleibt SG-Ready nur über einen gewissen Zeitraum
              aktiv und wird auch nicht mehrmals am Tag aktiviert. Zudem wird
              der PV-Einschaltbefehl deaktiviert, wenn bspw. aufgrund zu
              geringer Erzeugung oder ungewöhnlich hohen Verbrauches entgegen
              der Vorhersage nicht genug Überschuss vorhanden ist.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}

PageInstructions.propTypes = {
  session: PropTypes.object,
};
