import React from "react";
import { PropTypes } from "prop-types";

import { Grid, Typography } from "@mui/material";

// import LocationFormModal from './locations/LocationFormModal';
import Table from "../../../react_utils/table/Table";
import { round } from "../../../react_utils/utils";

export default function LocationDevices({ location, session }) {
  if (!location) return null;

  return (
    <Grid container rowSpacing={10} columnSpacing={2}>
      {location.inverter_set && location.inverter_set.length > 0 ? (
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" align="center">
            Wechselrichter
          </Typography>
          <Table
            columns={[
              { name: "", key: "name" },
              { name: "ID", key: "serial" },
              { name: "Batterie", key: "battery_capacity" },
              { name: "Status", key: "state", style: { width: "40%" } },
            ]}
            rows={location.inverter_set.map((inverter) => ({
              name: inverter.name,
              serial: inverter.serial,
              battery_capacity: `${round(inverter.battery_capacity, 2)} kWh`,
              state: inverter.state_representation,
            }))}
          />
        </Grid>
      ) : null}
      {location.wallbox_set && location.wallbox_set.length > 0 ? (
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" align="center">
            Wallboxen
          </Typography>
          <Table
            columns={[
              { name: "", key: "name" },
              { name: "Status", key: "state", style: { width: "40%" } },
            ]}
            rows={location.wallbox_set.map((wallbox) => ({
              name: wallbox.name,
              state: wallbox.state_representation,
            }))}
          />
        </Grid>
      ) : null}

      {location.heatpump_set && location.heatpump_set.length > 0 ? (
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" align="center">
            Wärmepumpen
          </Typography>
          <Table
            columns={[
              { name: "", key: "name" },
              { name: "Status", key: "state", style: { width: "40%" } },
            ]}
            rows={location.heatpump_set.map((heatpump) => ({
              name: heatpump.name,
              state: heatpump.state_representation,
            }))}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

LocationDevices.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object,
};
