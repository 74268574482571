import React, { useState } from "react";
import { PropTypes } from "prop-types";

import { Grid } from "@mui/material";

import GrowattAccountFormModal from "./GrowattAccountFormModal";
import Table from "../../../react_utils/table/Table";

export default function LocationGrowattAccounts({
  location,
  resetParent,
  session,
}) {
  const [chosenGrowattAccount, setChosenGrowattAccount] = useState(null);

  if (!location) return null;

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      item
      justifyContent="center"
      alignItems="center"
    >
      <GrowattAccountFormModal
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <GrowattAccountFormModal
        growattAccount={chosenGrowattAccount}
        isOpen={!!chosenGrowattAccount}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenGrowattAccount(null);
        }}
        getOpenButton={null}
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <Table
        columns={[
          { name: "Nutzername", key: "username" },
          { name: "Passwort", key: "password" },
        ]}
        rows={location.growattaccount_set.map((account) => ({
          username: account.username,
          password: "\u00b7".repeat(account.password.length),
          link: () => setChosenGrowattAccount(account),
        }))}
      />
    </Grid>
  );
}

LocationGrowattAccounts.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object,
};
