import React, { useState } from "react";
import { PropTypes } from "prop-types";

import CustomModal from "../../../react_utils/modals/CustomModal";
import Heatmap from "../../../react_utils/plots/Heatmap";
import {
  arrange,
  date2FormattedString,
  round,
  string2Date,
  transpose,
} from "../../../react_utils/utils";
import { API_URL_CLIENTREQUESTHEATMAP } from "../../../urls";
import { Box, CircularProgress } from "@mui/material";

export default function ClientRequestHeatmapModal({
  isOpen,
  setIsOpen,
  client,
  session,
}) {
  const [heatmap, setHeatmap] = useState(null);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setHeatmap(null);
  };

  const loadData = () => {
    session.backendClient
      .get(API_URL_CLIENTREQUESTHEATMAP, { params: { client_id: client.id } })
      .then((res) => setHeatmap(res.data.heatmap));
  };

  const xTicks =
    heatmap &&
    heatmap.map((h) => date2FormattedString(string2Date(h.date), false));
  const yTicks = arrange(24).map((i) => `${String(i).padStart(2, "0")}:00`);
  const matrix = heatmap && transpose(heatmap.map((h) => h.values));
  return (
    <CustomModal
      size="lg"
      title={"Verbindungsqualität"}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {heatmap ? (
        <Heatmap
          height={600}
          minValue={0}
          maxValue={100}
          matrix={matrix}
          xTicks={xTicks}
          yTicks={yTicks}
          tooltipText={(value, xIdx, yIdx) =>
            `${xTicks[xIdx]} ${yTicks[yIdx]}: ${round(value)}`
          }
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </CustomModal>
  );
}

ClientRequestHeatmapModal.propTypes = {
  client: PropTypes.object,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
