import React, { Fragment, useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import { Grid } from "@mui/material";

import EmployeeFormModal from "./EmployeeFormModal";
import {
  string2FormattedString,
  useDidMountEffect,
} from "../../../react_utils/utils";
import LoadingPage from "../../../react_utils/LoadingPage";
import { API_URL_EMPLOYEE } from "../../../urls";
import CharsInput from "../../../react_utils/fields/CharsInput";
import Table from "../../../react_utils/table/Table";
import TablePagination from "../../../react_utils/table/TablePagination";

export default function Employees({ session }) {
  const [employees, setEmployees] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [chosenEmployee, setChosenEmployee] = useState(null);

  // pagination
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);
  const batchSize = 20;

  // search filter
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    if (!loaded) return; // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      if (batch !== 0) setBatch(0);
      else getEmployees();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  useDidMountEffect(() => {
    // run if 'batch' changes, but not on initial render
    resetState();
  }, [batch]);

  const resetState = () => {
    setLoaded(false);
    getEmployees().then(() => setLoaded(true));
  };

  const getEmployees = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      search: searchInput || null,
      ascending_ids: false,
    };
    return session.backendClient
      .get(API_URL_EMPLOYEE, { params })
      .then((res) => {
        setNPages(Number(res.headers.length));
        setEmployees(res.data);
      });
  };

  return (
    <Fragment>
      {!loaded ? <LoadingPage /> : null}
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={6} item>
          <EmployeeFormModal resetParent={resetState} session={session} />
          <EmployeeFormModal
            resetParent={resetState}
            session={session}
            employee={chosenEmployee}
            isOpen={!!chosenEmployee}
            setIsOpen={(isOpen) => {
              if (!isOpen) setChosenEmployee(null);
            }}
            getOpenButton={() => null}
          />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          item
          display="flex"
          justifyContent="flex-end"
        >
          <CharsInput label="Suche" onChange={(v) => setSearchInput(v)} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          {employees ? (
            <Table
              columns={[
                { name: "Nachname", key: "last_name" },
                { name: "Vorname", key: "first_name" },
                { name: "Nutzer", key: "username" },
                { name: "E-Mail", key: "email" },
              ]}
              rows={employees.map((employee) => ({
                // key: `${employee.id}`,
                first_name: employee.first_name,
                last_name: employee.last_name,
                username: employee.username,
                email: employee.email,
                date_joined: string2FormattedString(employee.date_joined),
                link: (row) => {
                  setChosenEmployee(employee);
                },
              }))}
            />
          ) : null}
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display="flex"
          justifyContent="center"
          paddingTop={"10px"}
        >
          <TablePagination nPages={nPages} page={batch} setPage={setBatch} />
        </Grid>
      </Grid>
    </Fragment>
  );
}

Employees.propTypes = {
  session: PropTypes.object,
};
