import React, { Fragment } from "react";

import { PropTypes } from "prop-types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CustomAccordionSummary({ children, ...props }) {
  return (
    <AccordionSummary
      sx={{
        ".MuiAccordionSummary-content": {
          alignItems: "center",
          justifyContent: "center",
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
            background: "#323436",
          },
        },
      }}
      expandIcon={<ExpandMoreIcon color="secondary" />}
      {...props}
    >
      {children}
    </AccordionSummary>
  );
}

function InstallactionAccordion({ title, cards }) {
  const getSizeProps = (card) => {
    switch (card.size) {
      case "xl":
        return { xs: 12, lg: 12, xl: 8 };
      case "l":
        return { xs: 12, lg: 9 };
      case "xs":
        return { xs: 12, md: 4, lg: 3 };
      default:
        return { xs: 12, lg: 6, xl: 4 };
    }
  };
  return (
    <Accordion>
      <CustomAccordionSummary>
        <Typography align="center" fontSize="h6.fontSize" color="secondary">
          {title}
        </Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {cards.map((cardLine) => [
            ...cardLine.map((card) => (
              <Grid
                {...getSizeProps(card)}
                item
                justifyContent="center"
                alignItems="center"
              >
                <Card
                  sx={{
                    height: "100%",
                    padding: "10px",
                    backgroundColor: "tertiary.main",
                  }}
                >
                  <Typography
                    color="secondary"
                    align="center"
                    fontWeight="bold"
                  >
                    {card.title}
                  </Typography>
                  {card.img && (
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      display="flex"
                    >
                      {card.img && (
                        <img
                          src={card.img}
                          style={{
                            marginBottom: "20px",
                            marginTop: "20px",
                            maxWidth: "90%",
                          }}
                        />
                      )}
                    </Box>
                  )}
                  {card.content && (
                    <Box
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {card.content}
                    </Box>
                  )}
                </Card>
              </Grid>
            )),
            <Box width="100%" />,
          ])}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default function Instructions({}) {
  return (
    <Fragment>
      <Stack spacing={2}>
        <InstallactionAccordion
          title="Internet"
          cards={[
            [
              {
                title: "Impuls zum Internet verbinden",
                content: (
                  <Typography component="ul">
                    <li>
                      <Typography color="secondary" display="inline">
                        LAN:{" "}
                      </Typography>
                      <Typography display="inline">
                        Der Impuls verbindet sich automatisch zum Internet.
                      </Typography>
                    </li>
                    <li>
                      <Typography color="secondary" display="inline">
                        WLAN:{" "}
                      </Typography>
                      <Typography display="inline">
                        Um den Impuls mit dem WLAN zu verbinden, müssen die
                        nachfolgenden Schritte ausgeführt werden.
                      </Typography>
                    </li>
                  </Typography>
                ),
                size: "l",
              },
            ],
            [
              {
                title: "1) Handy mit Energieimpuls-WLAN verbinden",
                content: (
                  <Stack direction="row" paddingTop="20px">
                    <Typography>
                      SSID: impuls-
                      <i>
                        {"<"}xxx{">"}
                      </i>
                    </Typography>
                    <Typography>
                      Passwort:{" "}
                      <i>
                        {"<"}Seriennummer{">"}
                      </i>
                    </Typography>{" "}
                  </Stack>
                ),
                size: "xs",
              },
              {
                title: "2) In Browser zur Webseite navigieren",
                content: (
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    paddingTop="20px"
                  >
                    <img
                      src={"/instructions/qr_impuls_local_white.svg"}
                      alt="QR-Code"
                      height={"100px"}
                      margin={5}
                    />
                    <a
                      href={"http://10.41.0.1:8080"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography color="primary">
                        http://10.41.0.1:8080
                      </Typography>
                    </a>
                  </Stack>
                ),
                size: "xs",
              },
              {
                title: "3) Internetverbindung herstellen",
                content: (
                  <Typography component="ul" paddingTop="20px">
                    <Typography component="li">
                      Kunden-WLAN auswählen und Passwort eingeben
                    </Typography>
                    <Typography component="li">
                      Meldung "Internetverbindung erfolgreich" abwarten
                    </Typography>
                  </Typography>
                ),
                size: "xs",
              },
            ],
            [
              {
                title: "Hinweise",
                content: (
                  <Stack dirction="row">
                    <Typography color="secondary" fontWeight="bold">
                      Keine WLAN-Verbindung kann hergestellt werden
                    </Typography>
                    <Typography component="ul">
                      <Typography component="li">
                        WLAN-Signalstärke könnte zu schwach sein. Bitte mit dem
                        Smartphone prüfen, wie stark das Signal ist. Sonst muss
                        der Kunde einen WLAN-Repeater installieren.
                      </Typography>
                      <Typography component="li">
                        WPA3-Verschlüsselung (z.B. bei Telekom-Router "Speedport
                        Smart 4"). Die Verschlüsselungsmethode muss in der
                        Router-Konfiguration auf WPA2 umgestellt werden, da WPA3
                        nicht unterstützt wird.
                      </Typography>
                    </Typography>
                  </Stack>
                ),
                size: "l",
              },
            ],
          ]}
        />
        <InstallactionAccordion
          title="ABL Wallbox"
          cards={[
            [
              { title: "Impuls", img: "/instructions/wallbox-impuls.svg" },
              { title: "Wallbox", img: "/instructions/wallbox.svg" },
            ],
          ]}
        />
        <InstallactionAccordion
          title="Growatt Wechselrichter"
          cards={[
            [
              { title: "Impuls", img: "/instructions/inverter-sph-impuls.svg" },
              { title: "SPH", img: "/instructions/inverter-sph.svg" },
            ],
            [
              {
                title: "Einstellungen SPH",
                size: "xl",
                content: (
                  <Typography component="ul" display="inline">
                    <li>
                      <Typography color="secondary">Adresse: 2</Typography>
                      <Typography>
                        Menu {"\u2192"} Grundparameter / Basic Parameters{" "}
                        {"\u2192"} RS485 Adresse {"\u2192"} COM Adresse
                      </Typography>
                    </li>
                    <li>
                      <Typography color="secondary">RS485: VPP</Typography>
                      <Typography>
                        Menu {"\u2192"} Connection / RS485 Settings {"\u2192"}{" "}
                        RS485
                      </Typography>
                    </li>
                  </Typography>
                ),
              },
            ],
            [
              { title: "MOD", img: "/instructions/inverter-mod.svg" },
              {
                title: "MOD-Stecker",
                img: "/instructions/inverter-mod-plug.svg",
              },
            ],
            [
              {
                title: "Einstellungen MOD",
                size: "xl",
                content: (
                  <Typography component="ul" display="inline">
                    <Typography color="secondary">Adresse: 3</Typography>
                    <li>
                      <Typography>Menüführung: Klick zum Navigieren, Doppelklick zum Auswählen</Typography>
                    </li>
                    <li>
                      <Typography>Eintragen: Klick zum Ändern, Doppelklick zum bestätigen</Typography>
                    </li>
                  </Typography>
                ),
              },
            ],
            [
              { title: "MID", img: "/instructions/inverter-mid.svg" },
              {
                title: "MID-Stecker",
                img: "/instructions/inverter-mid-plug.svg",
              },
            ],
            [
              {
                title: "Einstellungen MID",
                size: "xl",
                content: (
                  <Typography component="ul" display="inline">
                    <Typography color="secondary">Adresse: 3</Typography>
                    <li>
                      <Typography>Menüführung: Klick zum Navigieren, Doppelklick zum Auswählen</Typography>
                    </li>
                    <li>
                      <Typography>Eintragen: Klick zum Ändern, Doppelklick zum bestätigen</Typography>
                    </li>
                  </Typography>
                ),
              },
            ],
            [
              { title: "MAX", img: "/instructions/inverter-max.svg" },
              {
                title: "MAX-Stecker",
                img: "/instructions/inverter-max-plug.svg",
              },
            ],
            [
              {
                title: "Einstellungen MAX",
                size: "xl",
                content: (
                  <Stack direction="row" spacing={2}>
                    <Typography color="secondary">Adresse: 3</Typography>
                    <Typography>
                      Einstellung via ShinePhone-App oder Shinebus
                    </Typography>
                  </Stack>
                ),
              },
            ],
          ]}
        />
        <InstallactionAccordion
          title="Waveshare Relais"
          cards={[
            [
              { title: "Impuls", img: "/instructions/relay-impuls.svg" },
              { title: "Relais", img: "/instructions/relay.svg" },
            ],
          ]}
        />
        <InstallactionAccordion
          title="Eastron Zähler"
          cards={[
            [
              { title: "Impuls", img: "/instructions/emeter-impuls.svg" },
              { title: "Zähler", img: "/instructions/emeter.svg" },
            ],
            [
              {
                title: "Einstellungen",
                size: "xl",
                content: (
                  <Typography component="ul" display="inline">
                    <Typography component="li">Baudrate: 9600</Typography>
                    <Typography component="li">Parität: N</Typography>
                    <Typography component="li">Stoppbits: 1</Typography>
                    <Typography component="li">Adresse: 4</Typography>
                  </Typography>
                ),
              },
            ],
          ]}
        />
      </Stack>
    </Fragment>
  );
}

Instructions.propTypes = {
  session: PropTypes.object,
};
