import React from "react";
import Flow from "./pages/Flow";
import FlowHistory from "./pages/FlowHistory";
import Clients from "./pages/Clients";
import Users from "./pages/Users";
import ChargingHistory from "./pages/ChargingHistory";
import SgReadyHistory from "./pages/SgReadyHistory";
import Locations from "./pages/Locations";
import Location from "./pages/Location";
import Settings from "./pages/Settings";
import Help from "./pages/Help";
import Instructions from "./pages/Instructions";

const getAdditionalPages = (session, all) => {
  /**
   * gets the additional pages for the current user
   *
   * @typedef {object} Page
   * @property {string} icon - icon for the page in the menu
   * @property {string} key - identifier for the page
   * @property {string} name - name / header for the page
   * @property {string} url - url of the page
   * @property {node} node - react component
   * @property {boolean} permission - indicated if the user has permission to view the page
   *
   * @param {object} session The session object with user information
   * @param {boolean} all if true, all pages are returned, else only the permitted ones
   * @returns {array<Page>} navigation
   */
  const checkAccessRights = (url) => checkUrlAccessRights(session.user, url);
  return [
    {
      icon: "location",
      name: "Standort",
      key: "location",
      url: "standort/:id",
      permission: checkAccessRights("standort/:id"),
      node: <Location session={session} />,
    },
  ];
};

export const getNavigation = (session, all = false) => {
  /**
   * gets the naviation for the current user
   *
   * @typedef {object} Page
   * @property {string} icon - icon for the page in the menu
   * @property {string} key - identifier for the page
   * @property {string} name - name / header for the page
   * @property {string} url - url of the page
   * @property {node} node - react component
   * @property {boolean} permission - indicated if the user has permission to view the page
   * @property {array<Page>} subpages - subpages of page
   *
   * @param {object} session The session object with user information
   * @param {boolean} all if true, all pages are returned, else only the permitted ones
   * @returns {array<Page>} navigation
   */

  const checkAccessRights = (url) => checkUrlAccessRights(session.user, url);
  const fullNavigation = [
    {
      icon: "location",
      key: "locations",
      name: "Standorte",
      url: "standorte",
      permission: checkAccessRights("standorte"),
      node: <Locations session={session} />,
    },
    {
      icon: "device",
      key: "clients",
      name: "Steuergeräte",
      url: "steuergeraete",
      permission: checkAccessRights("steuergeraete"),
      node: <Clients session={session} />,
    },
    {
      icon: "person",
      key: "users",
      name: "Nutzer",
      url: "nutzer",
      permission: checkAccessRights("nutzer"),
      node: <Users session={session} />,
    },
    {
      icon: "flash",
      key: "energyflow",
      name: "Übersicht",
      url: "flow",
      permission: checkAccessRights("flow"),
      node: <Flow session={session} />,
    },
    {
      icon: "timeline",
      key: "flowhistory",
      name: "Flusshistorie",
      url: "flowhistorie",
      permission: checkAccessRights("flowhistorie"),
      node: <FlowHistory session={session} />,
    },
    {
      icon: "charge",
      key: "charginghistory",
      name: "Ladehistorie",
      url: "ladehistorie",
      permission: checkAccessRights("ladehistorie"),
      node: <ChargingHistory session={session} />,
    },
    // { icon: 'heatpump', key: 'heatpump', name: 'Wärmepumpe', url: 'waermepumpe', permission: checkAccessRights('waermepumpe'), node: <Heatpump session={session} /> },
    {
      icon: "heatpump",
      key: "heatpump",
      name: "Wärmepumpe",
      url: "waermepumpe",
      permission: checkAccessRights("waermepumpe"),
      node: <SgReadyHistory session={session} />,
    },
    {
      icon: "info",
      key: "help",
      name: "Hilfe",
      url: "hilfe",
      permission: checkAccessRights("hilfe"),
      node: <Help session={session} />,
      drawerAlignBottom: true,
    },
    {
      icon: "info",
      key: "instructions",
      name: "Anleitung",
      url: "anleitung",
      permission: checkAccessRights("anleitung"),
      node: <Instructions session={session} />,
      drawerAlignBottom: true,
    },
    {
      icon: "settings",
      key: "settings",
      name: "Einstellungen",
      url: "einstellungen",
      permission: checkAccessRights("einstellungen"),
      node: <Settings session={session} />,
      drawerAlignBottom: true,
    },
  ];
  return all ? fullNavigation : filterNavigationForPermission(fullNavigation);
};

export const getPages = (session, all = false) => {
  /**
   * gets the pages for the current user
   *
   * @typedef {object} Page
   * @property {string} icon - icon for the page in the menu
   * @property {string} key - identifier for the page
   * @property {string} name - name / header for the page
   * @property {string} url - url of the page
   * @property {node} node - react component
   * @property {boolean} permission - indicated if the user has permission to view the page
   *
   * @param {object} session The session object with user information
   * @param {boolean} all if true, all pages are returned, else only the permitted ones
   * @returns {array<Page>} pages
   */
  const pages = flattenNavigation(getNavigation(session, all));
  pages.push(...getAdditionalPages(session, all));
  return pages;
};

export const getDefaultPage = (navigation, session) => {
  return navigation.find((p) => p.key === "energyflow" && p.permission) || null;
};

export const checkUrlAccessRights = (user, url) => {
  if (!user) return false;
  let staff = true;
  let view = null;
  switch (url) {
    case "einstellungen":
      staff = null;
      break;
    case "steuergeraete":
    case "nutzer":
    case "standorte":
    case "standort/:id":
    case "anleitung":
      staff = true;
      break;
    case "hilfe":
      staff = false;
      break;
    case "flow":
      staff = false;
      view = "flow";
      break;
    case "flowhistorie":
      staff = false;
      view = "flow_history";
      break;
    case "ladehistorie":
      staff = false;
      view = "wallbox";
      break;
    case "waermepumpe":
      staff = false;
      view = "heatpump";
      break;
    default:
      return false;
  }
  if (
    staff !== null &&
    ((staff && !user.is_staff) || (!staff && user.is_staff))
  )
    return false;
  if (view !== null && !user.views[view]) return false;
  return true;
};

const flattenNavigation = (navigation) => {
  const flat = [];
  navigation.forEach((item) => {
    const { subpages, ...newItem } = item;
    flat.push(newItem);
    if (subpages) flat.push(...flattenNavigation(subpages));
  });
  return flat;
};

const filterNavigationForPermission = (navigation) => {
  const filtered = [];
  navigation.forEach((item) => {
    const { subpages, ...newItem } = item;
    if (newItem.url) {
      if (newItem.permission) filtered.push(newItem);
    } else {
      newItem.subpages = filterNavigationForPermission(subpages);
      if (newItem.subpages.length > 0) filtered.push(newItem);
    }
  });
  return filtered;
};
