import React from "react";
import { PropTypes } from "prop-types";

import { Stack, Button, CircularProgress, Typography } from "@mui/material";

import { CustomButton } from "../StyledElements";
import { isString } from "../utils";


export default function ConfirmationModalFooter({
  inProgress,
  submitError,
  onConfirm,
  onCancel,
  btnLabel,
  btnColor,
}) {
  return (
    <Stack direction="row" spacing={1}>
      {submitError ? (
        <Typography color={"red"}>
          {isString(submitError)
            ? submitError
            : "Anfrage konnte nicht an Server übermittelt werden!"}
        </Typography>
      ) : null}
      {inProgress ? <CircularProgress color="primary" size="30px" /> : null}
      <Button
        variant="contained"
        color={btnColor ? btnColor : "success"}
        onClick={onConfirm}
        disabled={inProgress}
      >
        {btnLabel || "Entfernen"}
      </Button>
      <CustomButton onClick={onCancel} disabled={inProgress}>
        Abbrechen
      </CustomButton>
    </Stack>
  );
}

ConfirmationModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  btnLabel: PropTypes.string,
  btnColor: PropTypes.string,
};
