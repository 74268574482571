import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";

import { Typography, Grid, Card, Stack, Button } from "@mui/material";
import { inputStyles } from "../../react_utils/StyledElements";

import { API_URL_PASSWORDCHANGE } from "../../urls";
import CharsInput from "../../react_utils/fields/CharsInput";
import CustomSnackbar from "../../react_utils/CustomSnackbar";

const styles = {
  ...inputStyles,
};


export default function Settings({ session }) {
  const [newPassword1, setNewPassword1] = useState(null);
  const [newPassword2, setNewPassword2] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  const showMsg = (severity, msg) => {
    setSnackbar({ open: true, msg: msg, severity: severity });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword1 !== newPassword2) {
      setNewPassword1(null);
      setNewPassword2(null);
      showMsg("error", "Die Passwörter stimmen nicht überein!");
    } else {
      const passwordFormData = new FormData();
      passwordFormData.append("new_password", newPassword1);
      // passwordFormData.append('new_password2', newPassword2)
      // Axios update_password API call
      session.backendClient
        .post(API_URL_PASSWORDCHANGE, passwordFormData)
        .then((res) => {
          showMsg("success", "Passwort erfolgreich geändert!");
          setNewPassword1(null);
          setNewPassword2(null);
        })
        .catch((error) => {
          showMsg("error", error.response.data.join(" "));
          setNewPassword1(null);
          setNewPassword2(null);
        });
    }
  };


  return (
    <Fragment>
      <Grid container justify="center">
        <Grid
          xs={12}
          md={6}
          lg={4}
          xl={3}
          item
          justifyContent="center"
          alignItems="center"
          sx={{ marginY: "20px" }}
        >
          <Card
            sx={{
              padding: "10px",
              backgroundColor: "tertiary.main",
            }}
          >
            <Typography color="secondary" variant="h6">
              Passwort ändern
            </Typography>
            <form sx={styles.form} noValidate onSubmit={handleSubmit}>
              <Stack direction={"column"} spacing={3}>
                <CharsInput
                  label="Passwort eingeben"
                  placeholder="Passwort"
                  type="password"
                  onChange={(v) => setNewPassword1(v)}
                />
                <CharsInput
                  label="Passwort erneut eingeben"
                  placeholder="Passwort"
                  type="password"
                  onChange={(v) => setNewPassword2(v)}
                />
                <Button
                  type="submit"
                  variant="outlined"
                  style={{ color: "#efefef", borderColor: "#efefef" }}
                  sx={styles.submit}
                >
                  Passwort ändern
                </Button>
              </Stack>
            </form>
          </Card>
        </Grid>
      </Grid>
      <CustomSnackbar
        message={snackbar.msg}
        duration={3000}
        severity={snackbar.severity}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

Settings.propTypes = {
  session: PropTypes.object,
};
