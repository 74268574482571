import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";

import LoadingPage from "../../react_utils/LoadingPage";
import { API_URL_LOCATION } from "../../urls";
import TabView from "../../react_utils/TabView";
import LocationUsers from "./location/LocationUsers";
import LocationDevices from "./location/LocationDevices";
import LocationGrowattAccounts from "./location/LocationGrowattAccounts";
import LocationClients from "./location/LocationClients";

import FlowTab from "./location/FlowTab";
import ChargingHistoryTab from "./location/ChargingHistoryTab";
import SgReadyHistoryTab from "./location/SgReadyHistoryTab";
import FlowHistoryTab from "./location/FlowHistoryTab";
import { Typography } from "@mui/material";

export default function Location({ session }) {
  const { id } = useParams();

  const [location, setLocation] = useState(null);

  useEffect(() => {
    resetState();
  }, []);

  const resetState = () => {
    getLocation();
  };

  const getLocation = async () => {
    const params = {
      nested: true,
    };
    return session.backendClient
      .get(API_URL_LOCATION + id, { params })
      .then((res) => {
        setLocation(res.data);
      });
  };

  return (
    <Fragment>
      {!location ? <LoadingPage /> : null}
      {location?.name && (
        <Typography align="center">{location.name}</Typography>
      )}
      {location?.address && (
        <Typography align="center">{location.address}</Typography>
      )}
      {location && (
        <TabView
          components={[
            {
              title: "Nutzer",
              getChild: () => (
                <LocationUsers
                  location={location}
                  resetParent={resetState}
                  session={session}
                />
              ),
            },
            {
              title: "Geräte",
              getChild: () => (
                <LocationDevices location={location} session={session} />
              ),
            },
            {
              title: "Growattkonten",
              getChild: () => (
                <LocationGrowattAccounts
                  location={location}
                  resetParent={resetState}
                  session={session}
                />
              ),
            },
            {
              title: "Steuergeräte",
              getChild: () => (
                <LocationClients
                  location={location}
                  resetParent={resetState}
                  session={session}
                />
              ),
            },
            {
              title: "Flow",
              getChild: () => <FlowTab location={location} session={session} />,
            },
            ...(location.inverter_set.length > 0
              ? [
                  {
                    title: "Flusshistorie",
                    getChild: () => (
                      <FlowHistoryTab location={location} session={session} />
                    ),
                  },
                ]
              : []),
            ...(location.wallbox_set.length > 0
              ? [
                  {
                    title: "Ladehistorie",
                    getChild: () => (
                      <ChargingHistoryTab
                        location={location}
                        session={session}
                      />
                    ),
                  },
                ]
              : []),
            ...(location.heatpump_set.length > 0
              ? [
                  {
                    title: "SG-Ready",
                    getChild: () => (
                      <SgReadyHistoryTab
                        location={location}
                        session={session}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </Fragment>
  );
}

Location.propTypes = {
  session: PropTypes.object,
};
