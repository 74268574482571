import React, { Fragment, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { PropTypes } from "prop-types";

import { Grid, Chip, IconButton, CircularProgress, Stack } from "@mui/material";

import { API_URL_CLIENT } from "../../../urls";
import LoadingPage from "../../../react_utils/LoadingPage";
import ClientLocationFormModal from "./ClientLocationFormModal";
import ClientConfigureFormModal from "./ClientConfigureFormModal";

import {
  useDidMountEffect,
  string2Datetime,
  datetime2FormattedString,
} from "../../../react_utils/utils";
import Table from "../../../react_utils/table/Table";
import TablePagination from "../../../react_utils/table/TablePagination";
import CharsInput from "../../../react_utils/fields/CharsInput";
import Icon from "../../../react_utils/Icon";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import ConfirmationModal from "../../../react_utils/modals/ConfirmationModal";
import { sshTunnel } from "./sshTunnel";
import ClientRequestHeatmapModal from "./ClientRequestHeatmapModal";

export default function Clients({ assigned, configured, session }) {
  const [clients, setClients] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [chosenClient, setChosenClient] = useState(null);

  // chosen device to delete date
  const [chosenDateClient, setChosenDateClient] = useState(null);

  // chosen device for ssh tunnel
  const [chosenSshClientIds, setChosenSshClientIds] = useState([]);

  // chosen device to show network stats
  const [chosenNetStatsClient, setChosenNetStatsClient] = useState(null);

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  // pagination
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);
  const batchSize = 20;

  // search filter
  const [searchInput, setSearchInput] = useState(null);

  // order
  const [orderBy, setOrderBy] = useState("-first_query_timestamp");

  // columns filtering
  const [onlineFilter, setOnlineFilter] = useState(null);
  const [beenOnlineFilter, setBeenOnlineFilter] = useState(true);

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    if (!loaded) return; // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      if (batch !== 0) setBatch(0);
      else getClients();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  useDidMountEffect(() => {
    if (batch !== 0) setBatch(0);
    else getClients();
  }, [orderBy, onlineFilter, beenOnlineFilter]);

  useDidMountEffect(() => {
    // run if 'batch' changes, but not on initial render
    resetState();
  }, [batch]);

  const resetState = () => {
    setLoaded(false);
    getClients().then(() => {
      setLoaded(true);
    });
  };

  const getClients = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      search: searchInput || null,
      assigned: assigned,
      configured: configured,
      ascending_ids: false,
      order_by: orderBy,
      online: onlineFilter,
      been_online: beenOnlineFilter,
    };
    return session.backendClient.get(API_URL_CLIENT, { params }).then((res) => {
      setNPages(Number(res.headers.length));
      setClients(res.data);
    });
  };

  const _sshTunnel = async (clientId, event) => {
    setChosenSshClientIds((l) => [...l, clientId]);
    sshTunnel(
      clientId,
      session,
      () =>
        setSnackbar((s) => ({
          ...s,
          open: true,
          msg: "Der SSH-Tunnel wurde geöffnet.",
          severity: "success",
        })),
      () =>
        setSnackbar((s) => ({
          ...s,
          open: true,
          msg: "Der SSH-Tunnel konnte nicht geöffnet werden.",
          severity: "error",
        })),
      () => setChosenSshClientIds((l) => l.filter((i) => i !== clientId)),
      event.ctrlKey
    );
  };

  const getOrderBy = (key) => {
    switch (orderBy) {
      case key:
        return true;
      case `-${key}`:
        return false;
      default:
        return null;
    }
  };

  const updateOrderBy = (key, ascending) => {
    setOrderBy(ascending === null ? null : ascending ? key : `-${key}`);
  };

  return (
    <Fragment>
      {!loaded ? <LoadingPage /> : null}
      <Grid container justify="center">
        <Grid container justifyContent="flex-end">
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <CharsInput label="Suche" onChange={(v) => setSearchInput(v)} />
            <IconButton
              sx={{ p: 0 }}
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
              onClick={() => getClients()}
            >
              <Icon
                icon="refresh"
                fontSize="small"
                sx={{ color: "primary.main", marginTop: "15px !important" }}
              />
            </IconButton>
          </Stack>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          {clients && (
            <Table
              columns={[
                {
                  name: "Geräte-ID",
                  key: "id",
                  sort: {
                    value: getOrderBy("serial"),
                    setValue: (ascending) => updateOrderBy("serial", ascending),
                  },
                },
                { name: "Standort", key: "location" },
                {
                  name: "",
                  key: "location_link",
                  getLink: () => null,
                  headerprops: { style: { width: 60 } },
                },
                {
                  name: "Zuletzt online",
                  key: "timestamp",
                  sort: {
                    value: getOrderBy("latest_query_timestamp"),
                    setValue: (ascending) =>
                      updateOrderBy("latest_query_timestamp", ascending),
                  },
                },
                {
                  name: "Online seit",
                  key: "online_since",
                  headerprops: { style: { width: 250 } },
                  onActive: (row) => {
                    return row.client.first_query_timestamp ? (
                      <IconButton
                        sx={{ p: 0 }}
                        disableFocusRipple
                        disableRipple
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setChosenDateClient(row.client)}
                      >
                        <Icon icon={"bin"} color="error" fontSize="small" />
                      </IconButton>
                    ) : null;
                  },
                  getLink: () => null,
                  sort: {
                    value: getOrderBy("first_query_timestamp"),
                    setValue: (ascending) =>
                      updateOrderBy("first_query_timestamp", ascending),
                  },
                  filter: {
                    value: beenOnlineFilter,
                    setValue: setBeenOnlineFilter,
                    options: [
                      { value: true, label: "bereits online" },
                      { value: false, label: "noch nie online" },
                    ],
                  },
                },
                {
                  name: "",
                  key: "state",
                  filter: {
                    value: onlineFilter,
                    setValue: setOnlineFilter,
                    options: [
                      {
                        value: true,
                        label: (
                          <Chip
                            label="Online"
                            variant="outlined"
                            color="secondary"
                          />
                        ),
                      },
                      {
                        value: false,
                        label: (
                          <Chip
                            label="Offline"
                            variant="outlined"
                            color="error"
                          />
                        ),
                      },
                    ],
                  },
                },
                session.user.is_superuser && {
                  name: "Version",
                  key: "git_hash",
                  sort: {
                    value: getOrderBy("git_hash"),
                    setValue: (ascending) =>
                      updateOrderBy("git_hash", ascending),
                  },
                },
                session.user.is_superuser && {
                  name: "",
                  key: "ssh",
                  headerprops: { style: { width: 60 } },
                  onActive: (row) =>
                    !chosenSshClientIds.includes(row.client.id) && (
                      <IconButton
                        sx={{ p: 0 }}
                        disableFocusRipple
                        disableRipple
                        style={{ backgroundColor: "transparent" }}
                        onClick={(e) => _sshTunnel(row.client.id, e)}
                      >
                        <Icon
                          icon={"ssh"}
                          sx={{ color: "secondary.main" }}
                          fontSize="small"
                        />
                      </IconButton>
                    ),
                  getLink: () => null,
                },
                {
                  name: "",
                  key: "netstats",
                  headerprops: { style: { width: 60 } },
                  onActive: (row) => (
                    <IconButton
                      sx={{ p: 0 }}
                      disableFocusRipple
                      disableRipple
                      style={{ backgroundColor: "transparent" }}
                      onClick={(e) => setChosenNetStatsClient(row.client)}
                    >
                      <Icon
                        icon={"wifi"}
                        sx={{ color: "secondary.main" }}
                        fontSize="small"
                      />
                    </IconButton>
                  ),
                  getLink: () => null,
                },
              ].filter((x) => x)}
              rows={clients.map((client) => ({
                id: client.serial,
                client: client,
                location_link: client.location && (
                  <Link
                    to={`/standort/${client.location}`}
                    style={{ textDecoration: "none", width: "100%" }}
                  >
                    <Icon
                      icon={"location"}
                      sx={{ color: "secondary.main" }}
                      fontSize="small"
                    />
                  </Link>
                ),
                location:
                  client._location?.name || client._location?.address || "",
                timestamp: client.latest_query_timestamp
                  ? datetime2FormattedString(
                      string2Datetime(client.latest_query_timestamp)
                    )
                  : "",
                online_since: client.first_query_timestamp
                  ? datetime2FormattedString(
                      string2Datetime(client.first_query_timestamp)
                    )
                  : "",
                link: (row) => {
                  setChosenClient(client);
                },
                ssh: chosenSshClientIds.includes(client.id) && (
                  <CircularProgress
                    sx={{ color: "secondary.main" }}
                    size="20px"
                  />
                ),
                state: client.online ? (
                  <Chip label="Online" variant="outlined" color="secondary" />
                ) : (
                  <Chip label="Offline" variant="outlined" color="error" />
                ),
                git_hash: client.git_hash || "",
              }))}
              emptyTableText="Keine Steuergeräte"
            />
          )}
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display="flex"
          justifyContent="center"
          paddingTop={"10px"}
        >
          <TablePagination nPages={nPages} page={batch} setPage={setBatch} />
        </Grid>
      </Grid>
      <ClientLocationFormModal
        session={session}
        resetParent={resetState}
        client={chosenClient}
        isOpen={!!chosenClient && !chosenClient.location}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenClient(null);
        }}
      />
      <ClientConfigureFormModal
        session={session}
        resetParent={resetState}
        client={chosenClient}
        isOpen={!!chosenClient && !!chosenClient.location}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenClient(null);
        }}
      />
      <ConfirmationModal
        title={"Soll das Datum wirklich zurückgesetzt werden?"}
        resetParent={resetState}
        isOpen={!!chosenDateClient}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenDateClient(null);
        }}
        confirm={() => {
          return session.backendClient
            .put(API_URL_CLIENT + chosenDateClient.id, {
              first_query_timestamp: null,
            })
            .then(() => {
              setSnackbar((s) => ({
                ...s,
                open: true,
                msg: "Das Datum wurde gelöscht.",
                severity: "success",
              }));
            });
        }}
      />
      <ClientRequestHeatmapModal
        client={chosenNetStatsClient}
        isOpen={!!chosenNetStatsClient}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenNetStatsClient(null);
        }}
        session={session}
      />
      <CustomSnackbar
        message={snackbar.msg}
        duration={3000}
        severity={snackbar.severity}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

Clients.propTypes = {
  session: PropTypes.object,
};
