import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";

import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";
import TimePicker from "../../../react_utils/TimePicker";
import { string2Time } from "../../../react_utils/utils";

export default function HeatpumpSgReadyTimeModal({
  heatpump,
  editHeatpumpTime,
  resetParent,
  modalProps = {},
}) {
  const [selectedLowerTime, setSelectedLowerTime] = useState(null);
  const [selectedHigherTime, setSelectedHigherTime] = useState(null);

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  const onToggle = (isOpen) => {
    if (isOpen){
      setSelectedLowerTime(string2Time(heatpump.sg_ready_start_threshold));
      setSelectedHigherTime(string2Time(heatpump.sg_ready_end_threshold));
    }

    if (modalProps.onToggle) modalProps.onToggle(isOpen);
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={"Speichern"}
      />
    );
  };

  const onSubmit = async (onSuccess) => {
    return submit(selectedLowerTime, selectedHigherTime)
      .then((res) => {
        resetParent && resetParent();
        onSuccess();
        setSnackbar((s) => ({ ...s, msg: "Zeit geändert.", open: true }));
      })
      .catch((error) => {
        console.error(
          'Error in "ModifytimeModal:onSubmit"',
          error,
          error.stack
        );
      });
  };

  const submit = async (selectedLowerTime) => {
    return editHeatpumpTime(heatpump, selectedLowerTime, selectedHigherTime);
  };

  return (
    <Fragment>
      <CustomModal
        size="sm"
        title={"Anschaltzeit für SG-Ready einschränken"}
        getFooter={getFooter}
        {...modalProps}
        onToggle={onToggle}
      >
        <Stack spacing={2} marginY={"10px"}>
          <TimePicker
            time={selectedLowerTime}
            setTime={setSelectedLowerTime}
            label="Früheste Anschaltzeit für SG-Ready"
            showClearIcon={!!selectedLowerTime}
          />
          <TimePicker
            time={selectedHigherTime}
            setTime={setSelectedHigherTime}
            label="Späteste Anschaltzeit für SG-Ready"
            showClearIcon={!!selectedHigherTime}
          />
        </Stack>
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

HeatpumpSgReadyTimeModal.propTypes = {
  initial: PropTypes.string,
  editHeatpumpTime: PropTypes.func,
  session: PropTypes.object,
};
