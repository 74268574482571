import React, { useState } from "react";

import { PropTypes } from "prop-types";

import { Tabs, Tab, Typography, Box } from "@mui/material";

const tabStyle = {
  "& .MuiTab-root": {
    color: "#efefef",
  },
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    color: "#efefef",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#34a894",
  },
};

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.number,
  index: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabView({ components }) {
  // component: { title, child, onLoad }
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const onLoad = components[newValue].onLoad;
    if (onLoad) onLoad();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={tabStyle}
          variant="scrollable"
        >
          {components.map((component, idx) => (
            <Tab
              sx={tabStyle}
              key={`tab-title-${idx}`}
              label={component.title}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </Box>
      {components.map((component, idx) => (
        <CustomTabPanel key={`tab-${idx}`} value={value} index={idx}>
          {component.getChild()}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

TabView.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};
