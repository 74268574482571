import React from "react";
import { PropTypes } from "prop-types";

import { CircularProgress, Stack, Typography } from "@mui/material";

import { isString } from "../utils";
import { CustomButton } from "../StyledElements";

export default function SaveModalFooter({
  inProgress,
  submitError,
  disabled = false,
  onSave,
  onCancel,
  onDelete,
  saveBtnLabel = "Speichern",
  cancelBtnLabel = "Abbrechen",
  delteBtnLabel = "Löschen",
  saveDisabled,
  id,
}) {
  return (
    <Stack direction="row" spacing={1}>
      {inProgress ? <CircularProgress color="primary" size="30px" /> : null}
      {submitError ? (
        <Typography color={"red"}>
          {isString(submitError)
            ? submitError
            : "Anfrage konnte nicht an Server übermittelt werden!"}
        </Typography>
      ) : null}
      {onDelete ? (
        <CustomButton
          onClick={onDelete}
          disabled={inProgress || disabled}
          color="error"
        >
          {delteBtnLabel}
        </CustomButton>
      ) : null}
      <CustomButton
        id={id}
        onClick={onSave}
        disabled={inProgress || disabled || saveDisabled}
      >
        {saveBtnLabel}
      </CustomButton>

      {onCancel ? (
        <CustomButton onClick={onCancel} disabled={inProgress || disabled}>
          {cancelBtnLabel}
        </CustomButton>
      ) : null}
    </Stack>
  );
}

SaveModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  completeBtnLabel: PropTypes.string,
  saveBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  delteBtnLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
  completeDisabled: PropTypes.bool,
  id: PropTypes.string,
};
