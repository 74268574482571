import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import {
  IconButton,
  Typography,
  Collapse,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Icon from "../../react_utils/Icon";
import LogoHeader from "../../react_utils/LogoHeader";

export default function Home({ navigation, session }) {
  const history = useHistory();

  const [openCollapse, setOpenCollapse] = useState(null);

  const theme = useTheme(); // Access the MUI theme
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is xs

  const route = (item) => {
    history.push(`/${item.url}/`);
  };

  navigation = navigation.filter((navItem) => !navItem.drawerAlignBottom);

  const getItemComponent = (item) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon
          icon={item.icon}
          color="secondary"
          style={{ fontSize: 40 }}
          fontSize="large"
        />
        <Typography color="primary" fontSize="h6.fontSize">
          {item.name}
        </Typography>
      </Stack>
    );
  };

  const getItemIconButton = (item) => {
    return (
      <IconButton
        disabled
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
      >
        {getItemComponent(item)}
      </IconButton>
    );
  };

  const getParentItem = (item, level) => {
    return (
      <Box
        onClick={() => setOpenCollapse(item.key)}
        onMouseOver={() => setOpenCollapse(item.key)}
        onMouseLeave={() => setOpenCollapse(null)}
      >
        <Box sx={{ marginLeft: 40 * level }}>{getItemIconButton(item)}</Box>
        {item.subpages ? (
          <Collapse in={openCollapse === item.key} timeout="auto" unmountOnExit>
            {item.subpages.map((c) => getListItem(c, level + 1))}
          </Collapse>
        ) : null}
      </Box>
    );
  };

  const getChildItem = (item, level) => {
    return (
      <Box style={{ marginLeft: 40 * level }}>
        <Link to={`/${item.url}/`} style={{ textDecoration: "none" }}>
          {getItemIconButton(item)}
        </Link>
      </Box>
    );
  };

  const getListItem = (item, level) => {
    return item.url ? getChildItem(item, level) : getParentItem(item, level);
  };

  const onCardClick = (item) => {
    if (item.url) route(item);
    else setOpenCollapse(item.key);
  };

  const getNavigationItems = (navigation) => {
    if (!isSmallScreen) return navigation.map((item, idx) => <Box key={`navitem-${idx}`}>{getListItem(item, 0)}</Box>);
    return navigation.map((item, idx) => (
      <Card
        key={`navitem-${idx}`}
        onClick={() => onCardClick(item)}
        sx={{ padding: "5px", backgroundColor: "tertiary.main" }}
      >
        <CardMedia style={{ display: "flex", justifyContent: "center" }}>
          {getItemComponent(item)}
        </CardMedia>
        <Collapse
          ali
          in={openCollapse === item.key}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            {item.subpages ? item.subpages.map((c) => getListItem(c, 0)) : null}
          </CardContent>
        </Collapse>
      </Card>
    ));
  };

  return (
    <Fragment>
      {session.user ? (
        <Typography paddingTop={"10px"} color="primary" className={"text-end"}>
          Angemeldet: {session.user.username}{" "}
        </Typography>
      ) : null}
      <Typography
        fontSize="h4.fontSize"
        color="primary"
        align="center"
        sx={{ display: { md: "block", xs: "none" } }}
      >
        Home
      </Typography>
      <Grid container justify="center" sx={{ marginTop: "20px" }}>
        <Grid md={6} item sx={{ display: { md: "block", xs: "none" } }}>
          <LogoHeader maxHeight="300px" />
        </Grid>
        <Grid xs={12} md={6} item>
          <Stack direction="column" spacing={2}>
            {getNavigationItems(navigation)}
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
}

Home.propTypes = {
  session: PropTypes.object,
};
