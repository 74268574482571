import React from "react";
import { PropTypes } from "prop-types";
import { getColor } from "../../../react_utils/plots/plotUtils";
import InfoTooltip from "../../../react_utils/InfoTooltip";
import { filterObj } from "../../../react_utils/utils";

export const getColorByKey = (key, opacity = null) => {
  switch (key) {
    case "export_to_grid":
    case "prod_to_grid":
      return getColor("orange", opacity);
    case "import_from_grid":
    case "load_from_grid":
      return getColor("red", opacity);
    case "battery_charge":
    case "prod_to_bat":
    // return getColor('lightgreen', opacity)
    case "battery_discharge":
    case "load_from_bat":
    case "battery_soc":
      return getColor("green", opacity);
    // return getColor('yellow')
    case "production":
    case "load_from_prod":
      return getColor("cyan", opacity);
    case "load":
    case "prod_to_load":
      return getColor("blue", opacity);
    default:
      return getColor("grey", opacity);
  }
};

export function LoadHistoryTitle({ timeHorizon }) {
  return (
    <>
      Deckung des Verbrauchs{" "}
      <InfoTooltip>
        In diesem Graphen ist Ihr Energieverbrauch über{" "}
        {timeHorizon === "daily"
          ? "den Tag"
          : timeHorizon === "daily"
          ? "den Monat"
          : "das Jahr"}{" "}
        dargestellt. Dabei ist farblich markiert, welcher Anteil Ihres
        Verbrauchs jeweils durch Erzeugung, Netzbezug oder Energie aus Ihrer
        Batterie (falls vorhanden) gedeckt wurde.
      </InfoTooltip>
    </>
  );
}

LoadHistoryTitle.propTypes = {
  timeHorizon: PropTypes.oneOf(["hourly", "daily", "monthly"]),
};

export function ProductionHistoryTitle({ timeHorizon }) {
  return (
    <>
      Nutzung der Erzeugung{" "}
      <InfoTooltip>
        In diesem Graphen ist Ihre PV-Erzeugung über{" "}
        {timeHorizon === "daily"
          ? "den Tag"
          : timeHorizon === "daily"
          ? "den Monat"
          : "das Jahr"}{" "}
        dargestellt. Dabei sind die Anteile an der PV-Erzeugung farblich
        markiert, welche direkt verbraucht, in das Netz eingespeist oder zur
        Aufladung Ihrer Batterie (falls vorhanden) genutzt wurden.
      </InfoTooltip>
    </>
  );
}

ProductionHistoryTitle.propTypes = {
  timeHorizon: PropTypes.oneOf(["hourly", "daily", "monthly"]),
};

export const powerFlow = {
  export_to_grid: "Einspeisung",
  import_from_grid: "Netzbezug",
  battery_discharge: "Batterieentladung",
  production: "Erzeugung",
  load: "Last",
};
export const productionFlow = {
  prod_to_load: "Last",
  prod_to_bat: "Batterie",
  prod_to_grid: "Einspeisung",
};
export const consumptionFlow = {
  load_from_prod: "Erzeugung",
  load_from_bat: "Batterie",
  load_from_grid: "Netzbezug",
};
export const batterySoCFlow = { battery_soc: "Batterie-Ladezustand" };
export const getPowerFlow = (flowHistory) =>
  filterObj(powerFlow, (key) => key in flowHistory);
export const getProductionFlow = (flowHistory) =>
  filterObj(productionFlow, (key) => key in flowHistory);
export const getConsumptionFlow = (flowHistory) =>
  filterObj(consumptionFlow, (key) => key in flowHistory);
export const getBatterySoCFlow = (flowHistory) =>
  filterObj(batterySoCFlow, (key) => key in flowHistory);
