import React from "react";
import "reactflow/dist/style.css";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";

export default function DateInput({
  value,
  onChange,
  disabled = false,
  error = false,
  label = "Datum",
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DatePicker
        disableFuture={true}
        label={label}
        value={value ? dayjs(value) : null}
        disabled={disabled}
        onChange={(v) => {
          onChange(v.toDate());
        }}
        slotProps={{ textField: { variant: "standard", error: error } }}
        sx={{
          "& .MuiInputLabel-root.Mui-focused": { color: "#efefef" }, //styles the label
          "& .MuiInputLabel-root": { color: "#efefef" },
          "& .MuiSvgIcon-root": { color: "#efefef" },
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": { borderColor: "#efefef" },
            height: "48px",
            borderRadius: "6px",
          },
          input: {
            color: "#efefef",
            // border: "1px solid white"
          },
          inputfocused: {
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#efefef",
              },
          },
        }}
      />
    </LocalizationProvider>
  );
}

DateInput.propTypes = {};
