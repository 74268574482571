import React from "react";
import * as FileSaver from "file-saver";

import { CustomButton } from "./StyledElements";
import { PropTypes } from "prop-types";
const XLSX = await import("sheetjs-style");

export default function ExcelExport({ exporter, fileName, disabled }) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExternsion = ".xlsx";

  const exportToExcel = async () => {
    exporter().then((sheetArr) => {
      const wb = { Sheets: {}, SheetNames: [] };

      sheetArr.forEach((sheet) => {
        const { data, colors, name } = sheet;
        const ws = XLSX.utils.aoa_to_sheet(data);
        if (colors) {
          const colorWs = XLSX.utils.aoa_to_sheet(colors);
          Object.entries(ws).forEach(([key, value]) => {
            const color = colorWs[key]?.v;
            if (color) {
              value.s = {
                fill: {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { rgb: color },
                },
              };
            }
          });
        }
        wb.Sheets[name] = ws;
        wb.SheetNames.push(name);
      });

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const sheetData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(sheetData, fileName + fileExternsion);
    });
  };

  return (
    <CustomButton onClick={exportToExcel} disabled={disabled}>
      Export
    </CustomButton>
  );
}

ExcelExport.propTypes = {
  exporter: PropTypes.func,
  fileName: PropTypes.string,
  sheetName: PropTypes.string,
  disabled: PropTypes.bool,
};
