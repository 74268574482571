import React from "react";

import { PropTypes } from "prop-types";

import ModifyNameModal from "../common/ModifyNameModal";

export default function WallboxNameModal({
  wallbox,
  editWallboxName,
  resetParent,
  modalProps,
  session,
}) {
  return (
    <ModifyNameModal
      initial={wallbox.name}
      editName={(name) => editWallboxName(wallbox, name)}
      resetParent={resetParent}
      modalProps={modalProps}
    />
  );
}

WallboxNameModal.propTypes = {
  editWallboxName: PropTypes.func,
  session: PropTypes.object,
};
