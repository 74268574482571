import React, { Fragment, useEffect, useState } from "react";
import {
  addNMonths,
  date2FormattedString,
  month2String,
  string2Date,
  month2FormattedString,
  getMonthFromDate,
  getMonthRange,
} from "../../../react_utils/utils";
import DatePicker from "../../../react_utils/DatePicker";
import { Stack, Typography } from "@mui/material";
import ExcelExport from "../../../react_utils/ExcelExport";
import { getPowerFlow } from "./flowhistoryutils";

const MAXMONTHS = 12;

export default function DailyFlowDownloader({
  defaultDate,
  getSyncedFlow,
  session,
}) {
  const [exportStartDate, setExportStartDate] = useState(
    getMonthFromDate(defaultDate)
  );
  const [exportEndDate, setExportEndDate] = useState(
    getMonthFromDate(defaultDate)
  );

  useEffect(() => {
    if (exportStartDate > exportEndDate) setExportEndDate(exportStartDate);
  }, [exportStartDate]);

  useEffect(() => {
    setExportStartDate(getMonthFromDate(defaultDate));
    setExportEndDate(getMonthFromDate(defaultDate));
  }, [defaultDate]);

  const getExcelData = async () => {
    const months = getMonthRange(exportStartDate, exportEndDate);
    return Promise.all(
      months.map((month) =>
        getSyncedFlow(month).then((flow) => {
          return createExcelSheet(month, flow);
        })
      )
    );
  };

  const createExcelSheet = (month, flow) => {
    const powerFlow = getPowerFlow(flow);
    const header = ["Zeit", ...Object.values(powerFlow)];
    const rows = flow.time.map((t) => [date2FormattedString(string2Date(t))]);
    Object.keys(powerFlow).map((key) => {
      flow[key].forEach((val, idx) => rows[idx].push(val));
    });
    return { name: month2FormattedString(month), data: [header, ...rows] };
  };

  return (
    <Fragment>
      <Typography sx={{ marginBottom: "20px" }}>
        Historie als Exceldatei exportieren:
      </Typography>
      <Stack direction="row" spacing={2}>
        <DatePicker
          date={exportStartDate}
          setDate={setExportStartDate}
          label={"von"}
          views={["year", "month"]}
        />
        {exportStartDate && (
          <DatePicker
            date={exportEndDate}
            setDate={setExportEndDate}
            minDate={exportStartDate}
            maxDate={addNMonths(exportStartDate, MAXMONTHS)}
            label={"bis"}
            views={["year", "month"]}
          />
        )}
        <ExcelExport
          exporter={getExcelData}
          fileName={
            !(exportStartDate && exportEndDate)
              ? `Energiefluesse`
              : month2String(exportStartDate) == month2String(exportEndDate)
              ? `Energiefluesse-${month2String(exportStartDate)}`
              : `Energiefluesse-${month2String(exportStartDate)}-${month2String(
                  exportEndDate
                )}`
          }
          disabled={
            !(
              exportStartDate &&
              exportEndDate &&
              exportEndDate >= exportStartDate &&
              addNMonths(exportStartDate, MAXMONTHS) >= exportEndDate
            )
          }
        />
      </Stack>
      {!(addNMonths(exportStartDate, MAXMONTHS) >= exportEndDate) && (
        <Typography color="error">{`Daten von maximal ${MAXMONTHS} Monaten können gleichzeitig exportiert werden.`}</Typography>
      )}
    </Fragment>
  );
}
