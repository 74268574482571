import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";

import { useDidMountEffect } from "../../../react_utils/utils";
import { API_URL_LOCATION } from "../../../urls";
import CharsInput from "../../../react_utils/fields/CharsInput";
import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import { Stack } from "@mui/material";

export default function LocationInfoModal({
  location,
  resetParent,
  isOpen,
  setIsOpen,
  session,
}) {
  const [newLocationName, setNewLocationName] = useState(null);
  const [newLocationNotes, setNewLocationNotes] = useState(null);

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  useDidMountEffect(() => {
    setNewLocationNotes(location?.notes || "");
    setNewLocationName(location?.name || "");
  }, [location]);

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        disabled={!newLocationName && !newLocationNotes}
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={"Speichern"}
      />
    );
  };

  const onSubmit = async (onSuccess) => {
    return submit(newLocationName, newLocationNotes)
      .then((res) => {
        resetParent();
        onSuccess();
        setSnackbar((s) => ({ ...s, msg: "Infos geändert.", open: true }));
      })
      .catch((error) => {
        console.error(
          'Error in "LocationInfoModal:onSubmit"',
          error,
          error.stack
        );
      });
  };

  const submit = async (newLocationName, newLocationNotes) => {
    return session.backendClient.put(API_URL_LOCATION + location.id, {
      name: newLocationName,
      notes: newLocationNotes,
    });
  };

  return (
    <Fragment>
      <CustomModal
        size="sm"
        title={"Infos ändern"}
        getFooter={getFooter}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Stack direction="column" spacing={2}>
          <CharsInput
            label="Name"
            onChange={(v) => setNewLocationName(v)}
            value={newLocationName}
          />
          <CharsInput
            label="Kommentar"
            onChange={(v) => setNewLocationNotes(v)}
            value={newLocationNotes}
          />
        </Stack>
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

LocationInfoModal.propTypes = {
  session: PropTypes.object,
};
