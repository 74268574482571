import React from "react";

import { Alert, Snackbar } from "@mui/material";
import { PropTypes } from "prop-types";

export default function CustomSnackbar({
  message,
  duration,
  severity,
  open,
  setIsOpen,
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => setIsOpen(false)}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  message: PropTypes.string,
  duration: PropTypes.number,
  severity: PropTypes.oneOf(["success", "error", "info"]),
  open: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
