import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import {
  Grid,
  Button,
  Typography,
  Container,
  InputAdornment,
  Stack,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import LogoHeader from "../../react_utils/LogoHeader";

import { componentStyles } from "../../react_utils/StyledElements";
import CharsInput from "../../react_utils/fields/CharsInput";

export default function Login({ session }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (session.isAuthenticated) {
      history.replace(from);
    }
  }, [session.isAuthenticated]);

  const handleSubmit = (e) => {
    setLoginError(false);
    e.preventDefault();
    session.login(username, password).catch((err) => {
      setLoginError(true);
      console.error(err);
    });
  };
  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "90vh" }}
      >
        <Grid
          container
          spacing={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={8} md={5}>
            <LogoHeader maxHeight={{ xs: "180px", md: "300px" }} />
          </Grid>
          <Grid item xs={8} md={5}>
            <Container component="main" maxWidth="xs">
              <form
                sx={componentStyles.form}
                noValidate
                onSubmit={handleSubmit}
              >
                <Stack direction="column" spacing={2}>
                  <Typography
                    color="primary"
                    component="h1"
                    fontSize="h5.fontSize"
                    align="center"
                  >
                    Login
                  </Typography>
                  <CharsInput
                    fullWidth={true}
                    id="username"
                    name="username"
                    placeholder="Nutzer"
                    value={username}
                    onChange={(v) => setUsername(v)}
                  />
                  <CharsInput
                    fullWidth={true}
                    id="password"
                    name="password"
                    placeholder="Passwort"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(v) => setPassword(v)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            style={{ backgroundColor: "transparent" }}
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ color: "primary.main" }} />
                            ) : (
                              <Visibility sx={{ color: "primary.main" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    to={"/reset_password/"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      margin="normal"
                      sx={{
                        color: "primary.main",
                        textDecoration: "underline",
                        fontSize: "12px",
                      }}
                      onClick={() => {
                        history.push("/reset_password/");
                      }}
                    >
                      Passwort vergessen?
                    </Button>
                  </Link>
                  {loginError ? (
                    <Typography
                      color="error"
                      component="h1"
                      fontSize="h6.fontSize"
                    >
                      Benutzername oder Passwort falsch!
                    </Typography>
                  ) : null}
                  <Button
                    margin="normal"
                    onClick={handleSubmit}
                    type="submit"
                    variant="outlined"
                    style={{ color: "#efefef", borderColor: "#efefef" }}
                    sx={{ margin: (3, 0, 2) }}
                  >
                    Einloggen
                  </Button>
                </Stack>
              </form>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );

}

Login.propTypes = {
  session: PropTypes.object,
};
