import React from "react";
import "reactflow/dist/style.css";
import { PropTypes } from "prop-types";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";
import { popFromObj } from "./utils";

export default function DatePicker({
  date,
  setDate,
  disableFuture = true,
  minDate,
  maxDate,
  label = "Datum",
  ...props
}) {
  const propsSx = popFromObj(props, "sx") || {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DesktopDatePicker
        disableFuture={disableFuture}
        label={label}
        value={dayjs(date)}
        minDate={minDate && dayjs(minDate)}
        maxDate={maxDate && dayjs(maxDate)}
        onChange={(v) => {
          setDate(v.toDate());
        }}
        slotProps={{
          textField: { variant: "standard" },
          popper: {
            sx: {
              ".MuiPaper-root": {
                color: "white",
                backgroundColor: "tertiary.main",
              },
              ".MuiPickersYear-root": { color: "white" },
              ".MuiPickersYear-root .Mui-selected": {
                color: "white",
                backgroundColor: "#232323",
              },
              ".MuiPickersYear-root .Mui-disabled": { color: "#adadad" },
              ".MuiPickersMonth-root": { color: "white" },
              ".MuiPickersMonth-root .Mui-selected": {
                color: "white",
                backgroundColor: "#232323",
              },
              ".MuiPickersMonth-root .Mui-disabled": { color: "#adadad" },
              ".MuiPickersCalendarHeader-switchViewButton": { color: "white" },
              ".MuiPickersArrowSwitcher-button": { color: "white" },
            },
          },
        }}
        sx={{
          "& .MuiInputLabel-root.Mui-focused": { color: "#efefef" }, //styles the label
          "& .MuiInputLabel-root": { color: "#efefef" },
          "& .MuiSvgIcon-root": { color: "#efefef" },
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": { borderColor: "#efefef" },
            height: "48px",
            borderRadius: "6px",
          },
          input: {
            color: "#efefef",
            // border: "1px solid white"
          },
          inputfocused: {
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#efefef",
              },
          },
          ...propsSx,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  session: PropTypes.object,
};
