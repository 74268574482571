import React, { Fragment, useEffect, useState } from "react";
import {
  string2Date,
  month2FormattedString,
  getYearFromDate,
  getYearRange,
  year2FormattedString,
  addNYears,
  year2String
} from "../../../react_utils/utils";
import DatePicker from "../../../react_utils/DatePicker";
import { Stack, Typography } from "@mui/material";
import ExcelExport from "../../../react_utils/ExcelExport";
import { getPowerFlow } from "./flowhistoryutils";

const MAXYEARS = 10;

export default function MonthlyFlowDownloader({
  defaultDate,
  getSyncedFlow,
  session,
}) {
  const [exportStartDate, setExportStartDate] = useState(
    getYearFromDate(defaultDate)
  );
  const [exportEndDate, setExportEndDate] = useState(
    getYearFromDate(defaultDate)
  );

  useEffect(() => {
    if (exportStartDate > exportEndDate) setExportEndDate(exportStartDate);
  }, [exportStartDate]);

  useEffect(() => {
    setExportStartDate(getYearFromDate(defaultDate));
    setExportEndDate(getYearFromDate(defaultDate));
  }, [defaultDate]);

  const getExcelData = async () => {
    const years = getYearRange(exportStartDate, exportEndDate);
    return Promise.all(
      years.map((year) =>
        getSyncedFlow(year).then((flow) => {
          return createExcelSheet(year, flow);
        })
      )
    );
  };

  const createExcelSheet = (year, flow) => {
    const powerFlow = getPowerFlow(flow);
    const header = ["Zeit", ...Object.values(powerFlow)];
    const rows = flow.time.map((t) => [month2FormattedString(string2Date(t))]);
    Object.keys(powerFlow).map((key) => {
      flow[key].forEach((val, idx) => rows[idx].push(val));
    });
    return { name: year2FormattedString(year), data: [header, ...rows] };
  };

  return (
    <Fragment>
      <Typography sx={{ marginBottom: "20px" }}>
        Historie als Exceldatei exportieren:
      </Typography>
      <Stack direction="row" spacing={2}>
        <DatePicker
          date={exportStartDate}
          setDate={setExportStartDate}
          label={"von"}
          views={["year"]}
        />
        {exportStartDate && (
          <DatePicker
            date={exportEndDate}
            setDate={setExportEndDate}
            minDate={exportStartDate}
            maxDate={addNYears(exportStartDate, MAXYEARS)}
            label={"bis"}
            views={["year"]}
          />
        )}
        <ExcelExport
          exporter={getExcelData}
          fileName={
            !(exportStartDate && exportEndDate)
              ? `Energiefluesse`
              : year2String(exportStartDate) == year2String(exportEndDate)
              ? `Energiefluesse-${year2String(exportStartDate)}`
              : `Energiefluesse-${year2String(exportStartDate)}-${year2String(
                  exportEndDate
                )}`
          }
          disabled={
            !(
              exportStartDate &&
              exportEndDate &&
              exportEndDate >= exportStartDate &&
              addNYears(exportStartDate, MAXYEARS) >= exportEndDate
            )
          }
        />
      </Stack>
      {!(addNYears(exportStartDate, MAXYEARS) >= exportEndDate) && (
        <Typography color="error">{`Daten von maximal ${MAXYEARS} Jahren können gleichzeitig exportiert werden.`}</Typography>
      )}
    </Fragment>
  );
}
