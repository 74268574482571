import React from "react";

import { ToggleButtonGroup, styled, ToggleButton } from "@mui/material";

const CustomToggle = styled(ToggleButton)({
  color: "#34a894", // font color if not choosen
  borderColor: "#34a894",
  "&:hover": {
    backgroundColor: "transparent", // hover if not choosen
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#34a894",
    color: "#efefef",
  },
});

export default function Toggle({
  value,
  disabled = false,
  onChange,
  options,
  error = false,
}) {
  const _options = options.map((o) => ({
    value: o?.value || o,
    label: o?.label || o?.value || o,
  }));
  const _props = error ? { color: "error" } : {};

  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      exclusive
      disabled={disabled}
      onChange={(e, value) => onChange(value)}
      {..._props}
    >
      {_options.map((i, idx) => (
        <CustomToggle
          key={`toggle-${idx}`}
          id={`toggle-${idx}`}
          value={i.value}
        >
          {i.label}
        </CustomToggle>
      ))}
    </ToggleButtonGroup>
  );
}
