
const RGB_CHART_COLORS = {
  red: { r: 255, g: 99, b: 132 },
  orange: { r: 255, g: 159, b: 64 },
  yellow: { r: 255, g: 205, b: 86 },
  cyan: { r: 75, g: 192, b: 192 },
  blue: { r: 54, g: 162, b: 235 },
  purple: { r: 153, g: 102, b: 255 },
  green: { r: 74, g: 115, b: 55 },
  grey: { r: 201, g: 203, b: 207 },
  darkgreen: { r: 36, g: 64, b: 37 },
  lightgreen: { r: 144, g: 158, b: 62 },
};

export const getColor = (key, opacity = null) => {
  const c = RGB_CHART_COLORS[key];
  if (opacity !== null) {
    return `rgba(${c.r}, ${c.g}, ${c.b}, ${opacity})`;
  }
  return `rgb(${c.r}, ${c.g}, ${c.b})`;
};

export const getColorByIndex = (idx, opacity = null) => {
  const keys = Object.keys(RGB_CHART_COLORS);
  const key = keys[idx % keys.length];
  return getColor(key, opacity);
};
