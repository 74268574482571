import { useTheme } from "@emotion/react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import "./heatmap.css";
import { generateColorGradient } from "./colorgradient";

export default function Heatmap({
  matrix,
  xTicks,
  yTicks,
  height = 350,
  minValue,
  maxValue,
  tooltipText,
}) {
  const theme = useTheme();
  const series = matrix
    .map((values, idx) => ({ name: idx, data: values }))
    .reverse();
  const min = minValue != null ? minValue : Math.min(...matrix.flat());
  const max = maxValue != null ? maxValue : Math.max(...matrix.flat());
  const colorNSteps = 30;
  const options = {
    chart: {
      type: "heatmap",
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: "dark",
    },
    // colors: [theme.palette.secondary.main],//["#008FFB"],
    // title: {
    //   text: 'HeatMap Chart (Single color)'
    // },
    xaxis: {
      type: "category",
      categories: xTicks,
      tooltip: {
        enabled: false,
      },
      labels: {
        style: { fontSize: "16px" },
      },
    },
    yaxis: {
      labels: {
        formatter: (y) => (yTicks ? yTicks[y] : y),
        style: { fontSize: "16px" },
      },
      tooltip: {
        enabled: false,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // series != matrix (in series the rows are reversed)
        const value = series[seriesIndex][dataPointIndex];
        const yIndex = series.length - seriesIndex - 1;
        const xIndex = dataPointIndex;
        const text = tooltipText ? tooltipText(value, xIndex, yIndex) : value;
        return (
          '<div class="arrow_box" style="padding:10px">' +
          "<span>" +
          text +
          "</span>" +
          "</div>"
        );
      },
      // y: {
      //   formatter: (value, options) => value
      // },
      x: {
        show: false,
        // formatter: (value, options) => value
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            ...generateColorGradient(
              theme.palette.disabled.main,
              theme.palette.tertiary.main,
              20
            ),
            ...generateColorGradient(
              theme.palette.secondary.main,
              theme.palette.disabled.main,
              10
            ),
          ].map((color, i) => ({
            from: min + ((max - min) / colorNSteps) * i,
            to: min + ((max - min) / colorNSteps) * (i + 1),
            color: color,
            name: `step-${i}`,
          })),
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      height={height}
      type="heatmap"
    />
  );
}

Heatmap.propTypes = {};
