import React, { Fragment, useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import { Grid } from "@mui/material";

import {
  API_URL_cFLOW,
  API_URL_cWALLBOXSETPOINT,
  API_URL_cHEATPUMPSETPOINT,
  API_URL_cWALLBOX,
  API_URL_cHEATPUMP,
} from "../../urls";
import EnergyFlow from "./flow/EnergyFlow";
import CustomSnackbar from "../../react_utils/CustomSnackbar";
import WallboxCard from "./wallbox/WallboxCard";
import HeatpumpCard from "./heatpump/HeatpumpCard";
import { time2String } from "../../react_utils/utils";

export default function Flow({ session }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });
  const [flowData, setFlowData] = useState(null);

  const [wallboxSetPoints, setWallboxSetPoints] = useState(null);
  const [heatpumpSetPoints, setHeatpumpSetPoints] = useState(null);

  const resetState = () => {
    loadData();
  };

  const updateFlow = () => {
    session.backendClient.get(API_URL_cFLOW).then((res) => {
      setFlowData(res.data);
    });
  };

  const updateWallboxStates = () => {
    session.backendClient
      .get(API_URL_cWALLBOXSETPOINT, { params: { nested: true } })
      .then((res) => {
        setWallboxSetPoints(res.data);
      });
  };

  const updateHeatpumpStates = () => {
    session.backendClient
      .get(API_URL_cHEATPUMPSETPOINT, { params: { nested: true } })
      .then((res) => {
        setHeatpumpSetPoints(res.data);
      });
  };

  const loadData = () => {
    updateFlow();
    updateWallboxStates();
    updateHeatpumpStates();
  };

  useEffect(() => {
    loadData();
    const interval = setInterval(() => {
      loadData();
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const handleWallboxLockChange = (setpoint, locked) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_cWALLBOXSETPOINT + setpoint.id,
      { locked: locked }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "Die Wallbox wurde erfolgreich " +
            (locked ? "gesperrt!" : "enstperrt!"),
          open: true,
        }))
      );
  };

  const handleWallboxSurplusChange = (setpoint, surplus_charging) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_cWALLBOXSETPOINT + setpoint.id,
      { surplus_charging: surplus_charging }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "PV-Überschussladen wurde erfolgreich " +
            (surplus_charging ? "aktiviert!" : "deaktiviert!"),
          open: true,
        }))
      );
  };

  const handleWallboxHybridChange = (setpoint, hybrid_charging_current) => {
    const showSnackbar =
      !!setpoint.hybrid_charging_current !== !!hybrid_charging_current;
    if (showSnackbar) setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_cWALLBOXSETPOINT + setpoint.id,
      { hybrid_charging_current }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() => {
        if (showSnackbar)
          setSnackbar((s) => ({
            ...s,
            msg:
              "PV-Hybridladen wurde erfolgreich " +
              (hybrid_charging_current ? "aktiviert!" : "deaktiviert!"),
            open: true,
          }));
      });
  };

  // const handleHeatpumpSgReadyChange = (setpoint, sg_ready) => {
  //   setSnackbar(s => ({ ...s, open: false }))
  //   const promise = session.backendClient.put(API_URL_cHEATPUMPSETPOINT + setpoint.id, { sg_ready: sg_ready })
  //   return promise.then(res => setHeatpumpSetPoints(setPoints => {
  //     setPoints.forEach((sp) => {
  //       if (sp.id === setpoint.id) Object.assign(sp, res.data);
  //     })
  //     return [...setPoints]
  //   })).then(setSnackbar(s => ({ ...s, msg: "PV-Einschaltbefehl wurde erfolgreich " + (sg_ready ? "aktiviert!" : "deaktiviert!"), open: true })))
  // }

  const editWallboxName = (wallbox, name) => {
    return session.backendClient.put(API_URL_cWALLBOX + wallbox.id, { name });
  };

  const editHeatpumpName = (heatpump, name) => {
    return session.backendClient.put(API_URL_cHEATPUMP + heatpump.id, { name });
  };

  const editHeatpumpTime = (heatpump, lower, higher) => {
    return session.backendClient.put(API_URL_cHEATPUMP + heatpump.id, {
      sg_ready_start_threshold: time2String(lower),
      sg_ready_end_threshold: time2String(higher),
    });
  };

  // if (flowData === null) return <LoadingPage />

  return (
    <Fragment>
      <Grid container justify="center" spacing={3}>
        {flowData && (
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            display={"flex"}
            justifyContent="center"
            alignItems="center"
          >
            <EnergyFlow
              flowheight={{ xs: "400px", md: "600px" }}
              flowData={flowData}
            />
          </Grid>
        )}
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {(heatpumpSetPoints || []).map((heatpumpSetPoint) => (
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                item
                justifyContent="center"
                alignItems="center"
                key={`heatpump-card-wrapper-${heatpumpSetPoint.heatpump}`}
              >
                <HeatpumpCard
                  editHeatpumpName={
                    heatpumpSetPoints && heatpumpSetPoints.length > 1
                      ? editHeatpumpName
                      : null
                  }
                  editHeatpumpTime={editHeatpumpTime}
                  heatpumpSetPoint={heatpumpSetPoint}
                  resetParent={resetState}
                  session={session}
                />
              </Grid>
            ))}
            {(wallboxSetPoints || []).map((wallboxSetPoint) => (
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                item
                justifyContent="center"
                alignItems="center"
                key={`wallbox-card-wrapper-${wallboxSetPoint.wallbox}`}
              >
                <WallboxCard
                  editWallboxName={
                    wallboxSetPoints && wallboxSetPoints.length > 1
                      ? editWallboxName
                      : null
                  }
                  wallboxSetPoint={wallboxSetPoint}
                  setWallboxLocked={handleWallboxLockChange}
                  setWallboxSurplusCharging={handleWallboxSurplusChange}
                  // setWallboxHybridCharging={handleWallboxHybridChange}
                  resetParent={resetState}
                  session={session}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

Flow.propTypes = {
  session: PropTypes.object,
};
