import { API_URL_CLIENTSSHTUNNEL } from "../../../urls";

export const sshTunnel = async (
  clientId,
  session,
  onSuccess = null,
  onError = null,
  onFinished = null,
  portForwarding = false
) => {
  return session.backendClient
    .post(API_URL_CLIENTSSHTUNNEL, null, { params: { client_id: clientId } })
    .then((res) => {
      const { host, port, user } = res.data;
      const connectionStr = portForwarding
        ? `ssh -L 9090:localhost:8080 ${user}@${host} -p ${port}`
        : `ssh -p ${port} ${user}@${host}`;
      navigator.clipboard.writeText(connectionStr);
      onSuccess && onSuccess();
    })
    .catch((error) => {
      console.error('Error in "client:sshTunnel"', error, error.stack);
      onError && onError();
    })
    .then(() => {
      onFinished && onFinished();
    });
};
