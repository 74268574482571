

export const getNodeStyle = (sizePx) => (sizePx !== 0) ? {
  color: "inherit",
  border: "0px solid #2494a3",
  backgroundColor: "transparent",
  groupBackgroundColor: "rgba(240, 240, 240, 0.25)",
  boxshadowHover: "0 1px 4px 1px rgba(0, 0, 0, 0.08)",
  boxshadowSelected: "0 0 0 0.5px #1a192b",
  borderRadius: "80px",
  width: `${sizePx || 100}px`,
  height: `${sizePx || 100}px`,
  padding: "10px"
} : {
  border: "1px solid #ff000000",
  backgroundColor: "transparent",
  paddingTop: "20px"
}

