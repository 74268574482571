import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { HashLink as Link } from "react-router-hash-link";

import {
  Box,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import Collapse from "@mui/material/Collapse";

import { Drawer, AppBar, DrawerHeader } from "./drawerComponents";

import Icon from "../react_utils/Icon";

export default function MiniDrawer({ pageName, navigation, session }) {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);

  const routeHome = () => {
    history.push("/");
    handleDrawerClose();
  };

  const route = (item) => {
    history.push(`/${item.url}/`);
    handleDrawerClose();
  };

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  const refreshLogout = () => {
    routeHome();
    session.logout();
  };

  const getListItem = (item, level) => {
    return item.url ? getChildItem(item, level) : getParentItem(item, level);
  };

  const getChildItem = (item, level) => {
    return (
      <ListItem
        key={`child-item-${item.key}`}
        disablePadding
        sx={{ display: "block" }}
      >
        <Link to={`/${item.url}/`} style={{ textDecoration: "none" }}>
          <ListItemButton
            onClick={() => route(item)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5 + level * 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <Icon icon={item.icon} color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
            />
          </ListItemButton>
        </Link>
      </ListItem>
    );
  };

  const getParentItem = (item, level) => {
    return (
      <div
        key={`parent-item-${item.key}`}
        onClick={() => setOpenCollapse(item.key)}
        onMouseOver={() => setOpenCollapse(item.key)}
        onMouseLeave={() => setOpenCollapse(null)}
      >
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5 + level * 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <Icon icon={item.icon} color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
            />
          </ListItemButton>
        </ListItem>
        <Collapse in={openCollapse === item.key} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subpages.map((child) => getListItem(child, level + 1))}
          </List>
        </Collapse>
      </div>
    );
  };

  return (
    <Box
      zIndex={5000}
      position="sticky"
      sx={{ display: "flex", backgroundColor: "tertiary.main" }}
    >
      {isMobileOnly ? (
        <AppBar
          component="nav"
          position="sticky"
          open={open}
          sx={{ backgroundColor: "tertiary.main" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 1, ...(open && { display: "none" }) }}
            >
              <img
                src={`/logo_nobg.png`}
                alt="Logo"
                className="img-thumbnail"
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxHeight: "50px",
                }}
              />
            </IconButton>
            <Typography
              fontSize="h6.fontSize"
              sx={{ my: 2 }}
              color="primary"
              style={{ flex: 1 }}
            >
              {pageName}
            </Typography>
            {!open ? (
              <div>
                {session.isAuthenticated ? (
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      style={{ backgroundColor: "transparent" }}
                      onClick={routeHome}
                    >
                      {" "}
                      <HomeIcon color="secondary" />{" "}
                    </IconButton>
                  </Link>
                ) : null}
                {session.isAuthenticated ? (
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => {
                      session.logout();
                      routeHome();
                    }}
                  >
                    {" "}
                    <LogoutIcon color="secondary" />{" "}
                  </IconButton>
                ) : null}
              </div>
            ) : null}
          </Toolbar>
        </AppBar>
      ) : null}
      {!isMobileOnly || open ? (
        <Drawer
          variant="permanent"
          open={open}
          onMouseOver={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "tertiary.main",
              // backgroundImage: 'url("/drawer_background.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center center",
            },
          }}
        >
          {isMobileOnly ? (
            <DrawerHeader>
              <Typography
                color="primary"
                fontSize="h6.fontSize"
                component="div"
                style={{ flex: 1, marginLeft: "5px" }} // for align the following <div> to right
              >
                Menü
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                {open ? (
                  <ChevronRightIcon color="primary" />
                ) : (
                  <ChevronLeftIcon color="primary" />
                )}
              </IconButton>
            </DrawerHeader>
          ) : (
            <DrawerHeader>
              {open ? (
                <Typography
                  color="primary"
                  fontSize="h6.fontSize"
                  component="div"
                  style={{ flex: 1, marginLeft: "8px" }} // for align the following <div> to right
                >
                  Menü
                </Typography>
              ) : (
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`/logo_nobg.png`}
                      alt="Logo"
                      className="img-thumbnail"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        maxHeight: "50px",
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              )}
            </DrawerHeader>
          )}
          <Divider />
          <List>
            {navigation
              .filter((navItem) => !navItem.drawerAlignBottom)
              .map((navItem) => getListItem(navItem, 0))}
          </List>

          <List style={{ marginTop: `auto` }}>
            {session.isAuthenticated ? (
              <>
                {navigation
                  .filter((navItem) => navItem.drawerAlignBottom)
                  .map((navItem) => getListItem(navItem, 0))}
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => {
                      handleDrawerClose();
                      refreshLogout();
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LogoutIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Logout"}
                      sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : null}
          </List>
        </Drawer>
      ) : null}
    </Box>
  );
}

MiniDrawer.propTypes = {
  pageName: PropTypes.string,
  session: PropTypes.object,
};
