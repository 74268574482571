import React, { Fragment, useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import { Grid, IconButton } from "@mui/material";

import { useDidMountEffect } from "../../react_utils/utils";
import LoadingPage from "../../react_utils/LoadingPage";
// import LocationFormModal from './locations/LocationFormModal';
import { API_URL_LOCATION } from "../../urls";
import CharsInput from "../../react_utils/fields/CharsInput";
import Table from "../../react_utils/table/Table";
import TablePagination from "../../react_utils/table/TablePagination";
import Icon from "../../react_utils/Icon";
import LocationInfoModal from "./location/LocationInfoModal";

export default function Locations({ session }) {
  const [locations, setLocations] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // chosen location for location info
  const [chosenLocation, setChosenLocation] = useState(null);

  // pagination
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);
  const batchSize = 20;

  // search filter
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    if (!loaded) return; // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      if (batch !== 0) setBatch(0);
      else getLocations();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  useDidMountEffect(() => {
    // run if 'batch' changes, but not on initial render
    setLoaded(false);
    getLocations().then(() => setLoaded(true));
  }, [batch]);

  const resetState = () => {
    // setLocations(null)
    getLocations().then(() => setLoaded(true));
  };

  const getLocations = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      search: searchInput || null,
      ascending_ids: false,
    };
    return session.backendClient
      .get(API_URL_LOCATION, { params })
      .then((res) => {
        setNPages(Number(res.headers.length));
        setLocations(res.data);
      });
  };

  return (
    <Fragment>
      {!loaded ? <LoadingPage /> : null}
      <Grid container>
        <Grid container justifyContent="flex-end">
          <CharsInput label="Suche" onChange={(v) => setSearchInput(v)} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          {locations && (
            <Table
              columns={[
                { name: "Name", key: "name" },
                { name: "Straße Hausnr.", key: "street_and_number" },
                { name: "PLZ Ort", key: "zip_and_city" },
                { name: "Nutzer", key: "user" },
                { name: "Growattnutzer", key: "growattuser" },
                { name: "Steuergerät", key: "impulsclient" },
                { name: "Kommentar", key: "notes" },
                {
                  name: "",
                  key: "action",
                  headerprops: { style: { width: 60 } },
                  onActive: (row) => (
                    <IconButton
                      sx={{ p: 0 }}
                      disableFocusRipple
                      disableRipple
                      style={{ backgroundColor: "transparent" }}
                      onClick={() => setChosenLocation(row.location)}
                    >
                      <Icon
                        icon={"edit"}
                        sx={{ color: "secondary.main" }}
                        fontSize="small"
                      />
                    </IconButton>
                  ),
                  getLink: () => null,
                },
              ]}
              rows={locations.map((location) => ({
                name: location.name,
                street_and_number: location.street_and_number,
                zip_and_city: location.zip_and_city,
                user: location.user_names.join(", "),
                growattuser: location.growatt_account_names.join(", "),
                impulsclient: location.impuls_client_ids.join(", "),
                link: `/standort/${location.id}`,
                location: location,
                notes: location.notes,
              }))}
            />
          )}
        </Grid>
        <Grid xs={12} sm={12} md={12} item paddingTop={"10px"}>
          <TablePagination nPages={nPages} page={batch} setPage={setBatch} />
        </Grid>
      </Grid>
      <LocationInfoModal
        location={chosenLocation}
        resetParent={resetState}
        isOpen={!!chosenLocation}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenLocation(null);
        }}
        session={session}
      />
    </Fragment>
  );
}

Locations.propTypes = {
  session: PropTypes.object,
};
