import { Handle, Position, NodeToolbar } from "reactflow";
import { Typography } from "@mui/material";
import { getNodeStyle } from "./nodestyle";

export default function SolarNode({ data }) {
  return (
    <div style={getNodeStyle(data.sizePx)}>
      <div className="text-center">
        <img src={"/icons/pv.svg"} alt="PV Icon" height={"50px"} />
        <Typography variant="subtitle2" color="primary">
          PV
        </Typography>
      </div>
      <Handle
        type="source"
        id="bottom"
        className="handlebottom"
        position={Position.Bottom}
      />
      <Handle
        type="target"
        id="bottom"
        className="handlebottom"
        position={Position.Bottom}
      />
      <NodeToolbar>
        {/* position={data.toolbarPosition}> */}
        <button>delete</button>
        <button>copy</button>
        <button>expand</button>
      </NodeToolbar>
    </div>
  );
}
