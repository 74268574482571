import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import { Autocomplete } from "@mui/material";
import CharsInput from "./CharsInput";

export default function DropDown({
  onChange,
  options,
  label = "Auswählen",
  value,
  disabled,
  sort = false,
  onInputChange = null,
  error = false,
}) {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const newOptions = options
      ? options.map((s) => {
          return Object.prototype.toString.call(s) === "[object Object]"
            ? s
            : {
                value: s,
                label:
                  toString.call(s) === "[object String]" ? s : s.toString(),
              };
        })
      : [];
    if (sort) {
      newOptions.sort((a, b) => {
        if (a.order === "first" && b.order !== "first") return -1;
        if (a.order !== "first" && b.order === "first") return 1;
        if (a.order === "last" && b.order !== "last") return 1;
        if (a.order !== "last" && b.order === "last") return -1;
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }
    setDropdownOptions(newOptions);
  }, [options]);

  useEffect(() => {
    setSelected(dropdownOptions.find((i) => i.value === value));
  }, [value, dropdownOptions]);

  const autoselectclicked = (event, chosen) => {
    onChange(chosen ? chosen.value : null, value);
  };

  return (
    <Autocomplete
      disablePortal={true}
      sx={{
        width: 200,
        "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
          color: "tertiary.contrastText",
        }, // color of arrow button
        "& .Mui-disabled .MuiAutocomplete-endAdornment .MuiIconButton-root": {
          color: "tertiary.main",
        }, // color of arrow button if disabled
      }}
      options={dropdownOptions}
      disabled={disabled}
      onChange={autoselectclicked}
      value={selected || null}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) =>
        value ? option.value === value.value : false
      }
      getOptionDisabled={(option) => option.disabled}
      onInputChange={
        onInputChange ? (e, input) => onInputChange(input) : undefined
      }
      renderInput={(params) => {
        return (
          <CharsInput {...params} label={label} size="small" error={error} />
        );
      }}
    />
  );
}

DropDown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ])
  ),
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  sort: PropTypes.bool,
  onInputChange: PropTypes.func,
};
