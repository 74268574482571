import React, { Fragment } from "react";

import { PropTypes } from "prop-types";

import { Grid, Typography, Box, Link } from "@mui/material";

export default function ImpulsClientInstructions({ location, session }) {
  return (
    <Fragment>
      <Grid container justify="center">
        <Grid
          key="flow-instructions"
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
          marginY={3}
        >
          <Typography align="center" fontSize="h6.fontSize" color="secondary">
            Internetverbindung konfigurieren
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography>
            Falls sich Ihr WLAN-Zugang geändert hat und Ihr Energieimpuls kein
            Internet mehr hat, muss der Energieimpuls mithilfe Ihres Smartphones
            wieder ins WLAN eingebunden werden. Folgend finden Sie eine
            Schritt-für-Schritt-Anleitung, um den Energieimpuls wieder mit Ihrem
            WLAN zu verbinden:
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography color="secondary">
            Smartphone mit dem Energieimpuls verbinden
          </Typography>
          <Typography>
            Wenn der Energieimpuls keine Internetverbindung hat, öffnet er einen
            Hotspot mit folgenden Zugangsdaten:
          </Typography>
          {location.impulsclient_set.map((c) => (
            <Box marginY={1}>
              <Typography>
                SSID: impuls-{c.serial[6]}
                {c.serial[1]}
                {c.serial[3]}
              </Typography>
              <Typography>Passwort: {c.serial}</Typography>
            </Box>
          ))}
          <Typography>
            Verbinden Sie Ihr Smartphone in den WLAN-Einstellungen mit dem
            Hotspot des Energieimpulses. Ihr Smartphone muss während des
            gesamten Konfigurationsvorgangs mit diesem Hotspot verbunden
            bleiben. Dabei kann gelegentlich an Ihrem Smartphone eine Meldung
            erscheinen, dass keine Internetverbindung im WLAN verfügbar ist, da
            der Energieimpuls keine Internetverbindung bereitstellt. Ignorieren
            Sie diese Meldung Ihres Smartphones und bleiben Sie mit dem Hotspot
            des Energieimpulses verbunden
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography color="secondary">Zur Webseite navigieren</Typography>
          <Typography component="ul">
            <li>
              <Typography component="li">
                Option 1: Scannen Sie folgenden QR-Code:
              </Typography>
              <img
                src={"/instructions/qr_impuls_local_white.svg"}
                alt="QR-Code"
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  maxWidth: "100px",
                }}
              />
            </li>
            <Typography component="li">
              Option 2: Nutzen Sie folgenden Link:{" "}
              <Link
                href={"http://10.41.0.1:8080"}
                sx={{
                  textDecoration: "none",
                  "&:hover": { color: "secondary.main" },
                }}
              >
                http://10.41.0.1:8080
              </Link>
            </Typography>
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography color="secondary">WLAN-Daten eingeben</Typography>
          <Typography>
            Der Energieimpuls sucht nach verfügbaren WLAN-Netzwerken und zeigt
            diese an. Falls Ihr WLAN-Zugang nicht anzeigt wird, klicken Sie auf
            "Erneut suchen", um den Suchvorgang erneut zu wiederholen. Wählen
            Sie Ihren WLAN-Zugang, indem Sie das entsprechende Feld klicken, und
            geben Sie das Passwort in das entsprechende Feld ein. Klicken Sie
            anschließend "Anmelden".
          </Typography>
          <Typography>
            Der Energieimpuls gibt eine Meldung auf der Webseite aus, wenn
            erfolgreich eine Verbindung zum Internet hergestellt worden ist. Im
            Anschluss wird der WLAN-Hotspot automatisch ausgeschaltet. Dieser
            ist immer nur dann verfügbar, wenn keine Internetverbindung besteht.
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography color="secondary">
            Probleme bei der Herstellung einer WLAN-Verbindung
          </Typography>
          <Typography>
            Sollte trotz gefundenem WLAN-Netzwerk und korrekt eingegebenem
            Passwort keine Internetverbindung hergestellt werden, kann dies auf
            mehrere Probleme zurückzuführen sein:
          </Typography>
          <Typography component="ul">
            <Typography component="li">
              Die Verbindungsqualität des WLANs ist zu schlecht. Bitte
              verstärken Sie das Signal, bspw. mit einem Repeater.
            </Typography>
            <Typography component="li">
              Ihre WLAN-Verschlüsselung ist mit WPA3 konfiguriert (häufig bei
              Telekom Speedport Smart 4). Bitte konfigurieren Sie die
              Verschlüsselungsmethode zu WPA2 um, da WPA3 nicht unterstützt
              wird. Alternative: erzeugen Sie ein Gast-WLAN-Netzwerk mit
              eingeschränktem Zugriff und konfigurieren Sie nur für dieses
              WLAN-Netzwerk die WPA2-Methode.
            </Typography>
          </Typography>
        </Grid>
        <Grid
          key="flow-instructions"
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
          marginY={3}
        >
          <Typography align="center" fontSize="h6.fontSize" color="secondary">
            Hinweise
          </Typography>
          <Typography color="secondary">
            In der Flusshistorie sind viele Lücken in den Graphen zu sehen.
          </Typography>
          <Typography>
            Ist die Flusshistorie sehr lückenhaft, ist dies darauf
            zurückzuführen, dass Ihr Energieimpuls regelmäßig keine Daten
            sendet. Dies liegt an einer schlechten Internetverbindung. Wir raten
            Ihnen, eine bessere Internetverbindung herzustellen, um einen
            reibungslosen Betrieb des Energieimpulses sicherzustellen. Dazu
            eignet sich beispielsweise ein Repeater, um Ihr WLAN-Signal zu
            verstärken, oder bestenfalls eine direkte LAN-Verbindung zum
            Energieimpuls.
          </Typography>
          <Box sx={{ height: 10 }} />
          <Typography color="secondary">
            In der Energiefluss-Übersicht sind inkonsistente Zustände zu
            verzeichnen.
          </Typography>
          <Typography>
            Inkonsistente Zustände in der Flussübersicht können auf zwei Gründe
            zurückgeführt werden:
          </Typography>
          <Typography component="ul">
            <Typography component="li">
              Transiente Vorgänge des Wechselrichters: Ist ein inkonsistenter
              Zustand nur für ein paar Sekunden zu verzeichnen, kann dies darauf
              zurückgeführt werden, dass die Steuerungsvoränge des
              Wechselrichters zum Zeitpunkt des Auslesens noch nicht
              abgeschlossen waren.
            </Typography>
            <Typography component="li">
              Unterschiedliche zeitliche Auflösung der Daten: Ist Ihr
              Energieimpuls nicht an alle Ihre Wechselrichter angeschlossen,
              sondern werden Daten eines Wechselrichters direkt vom Anbieter
              (bspw. Growatt) ausgelesen, kann dies zur unterschiedlichen
              zeitlichen Auflösung der Daten führen. Während der Energieimpuls
              fortlaufend die Daten des Wechselrichters ausliest und in kurzen
              zeitlichen Abständen übermittelt, werden bei Anbietern wie bspw.
              Growatt, die Daten in 5-minütiger Auflösung zur Verfügung
              gestellt.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}

ImpulsClientInstructions.propTypes = {
  session: PropTypes.object,
};
