import React, { useState } from "react";

import { PropTypes } from "prop-types";

import { Typography, Grid, Stack } from "@mui/material";

import { API_URL_CLIENT } from "../../../urls";
import LoadingPage from "../../../react_utils/LoadingPage";
import {
  CustomButton,
  CustomIconButton,
} from "../../../react_utils/StyledElements";
import CustomModal from "../../../react_utils/modals/CustomModal";
import {
  useDidMountEffect,
  string2Datetime,
  datetime2FormattedString,
} from "../../../react_utils/utils";
import ClientLocationFormModal from "../clients/ClientLocationFormModal";
import Table from "../../../react_utils/table/Table";
import TablePagination from "../../../react_utils/table/TablePagination";

export default function AddClientModal({
  location,
  getOpenButton,
  resetParent,
  session,
}) {
  const [isChooseModalOpen, setIsChooseModalOpen] = useState(false);

  const [clients, setClients] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [chosenClient, setChosenClient] = useState(null);

  // pagination
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);
  const batchSize = 20;

  useDidMountEffect(() => {
    if (isChooseModalOpen) loadData();
    // else clearData() // is closed, when chosenClient is set to null
  }, [isChooseModalOpen]);

  useDidMountEffect(() => {
    if (!chosenClient) clearData();
  }, [chosenClient]);

  useDidMountEffect(() => {
    if (chosenClient && isChooseModalOpen) setIsChooseModalOpen(false);
  }, [chosenClient]);

  useDidMountEffect(() => {
    // run if 'batch' changes, but not on initial render
    if (isChooseModalOpen) {
      setLoaded(false);
      getClients().then(() => setLoaded(true));
    }
  }, [batch]);

  const loadData = () => {
    setLoaded(false);
    getClients().then(() => {
      setLoaded(true);
    });
  };

  const clearData = () => {
    setBatch(0);
    setNPages(null);
    setClients(null);
    setChosenClient(null);
  };

  const getClients = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      assigned: false,
      ascending_ids: false,
    };
    return session.backendClient.get(API_URL_CLIENT, { params }).then((res) => {
      setNPages(Number(res.headers.length));
      setClients(res.data);
    });
  };

  const _getOpenButton = (toggle) => {
    if (getOpenButton === null) return null;
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton
        onClick={toggle}
        style={{ maxWidth: "300px" }}
        icon="device"
      >
        Steuergerät hinzufügen
      </CustomButton>
    );
  };

  return (
    <>
      <CustomModal
        title="Gerät wählen"
        isOpen={isChooseModalOpen}
        setIsOpen={setIsChooseModalOpen}
        getOpenButton={_getOpenButton}
      >
        {!loaded ? <LoadingPage /> : null}
        <Grid container justify="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            justifyContent="center"
            alignItems="center"
          >
            {clients ? (
              <Table
                columns={[
                  { name: "Geräte-ID", key: "id" },
                  { name: "Zuletzt online", key: "timestamp" },
                ]}
                rows={clients.map((client) => ({
                  id: client.serial,
                  timestamp: datetime2FormattedString(
                    string2Datetime(client.latest_query_timestamp)
                  ),
                  link: (row) => {
                    setChosenClient(client);
                  },
                }))}
              />
            ) : null}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            display="flex"
            justifyContent="center"
            paddingTop={"10px"}
          >
            <TablePagination nPages={nPages} page={batch} setPage={setBatch} />
          </Grid>
        </Grid>
      </CustomModal>
      <ClientLocationFormModal
        location={location}
        session={session}
        resetParent={resetParent}
        client={chosenClient}
        isOpen={!!chosenClient}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenClient(null);
        }}
      />
    </>
  );
}

AddClientModal.propTypes = {
  location: PropTypes.object,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
