import React from "react";

import { TextField as MuiTextField } from "@mui/material";
import { inputStyles } from "../StyledElements";
import { popFromObj } from "../utils";

export default function CharsInput({
  value,
  disabled = false,
  onChange,
  inputRef,
  error = false,
  name,
  autoComplete,
  label = null,
  placeholder = "",
  ...props
}) {
  const _props =
    autoComplete === null
      ? {}
      : { autoComplete: autoComplete === true ? "on" : autoComplete || "off" };

  const sx = popFromObj(props, "sx");

  return (
    <MuiTextField
      value={value}
      size="small"
      type="text"
      disabled={disabled}
      label={label}
      name={name}
      sx={{ ...inputStyles.inputField, ...(sx || {}) }}
      // inputProps={{sx: inputStyles.newInput}}
      onChange={onChange ? (e) => onChange(e.target.value) : null}
      error={error}
      // focused
      placeholder={placeholder}
      inputRef={inputRef}
      variant={"standard"}
      {..._props}
      {...props}
    />
  );
}
