import { Box } from "@mui/material";
import React from "react";

export default function LogoHeader({ maxHeight = "350px" }) {
  return (
    <Box
      sx={{
        width: `100%`, // Fixed width
        height: maxHeight, // Fixed height, same as width for square box
        display: "flex", // Ensure proper alignment of content
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Ensure image doesn't overflow
      }}
    >
      <img
        src="/logo_login.png" // Replace with your image URL
        alt="Responsive"
        style={{
          width: "auto", // Make the image fill the box width
          height: "100%", // Adjust the height to maintain aspect ratio
        }}
      />
    </Box>
  );
}
