import Edge from "./Edge";
import FlowEdge from "./FlowEdge";

import { round } from "../../../../react_utils/utils";

const GRIDFLOW = {
  source: "grid",
  target: "inverter",
  sourceHandle: "top",
  targetHandle: "bottom",
};
const BATTERYFLOW = {
  source: "battery",
  target: "inverter",
  sourceHandle: "right",
  targetHandle: "left",
};
const PVFLOW = {
  source: "solar",
  target: "inverter",
  sourceHandle: "bottom",
  targetHandle: "top",
};
const CONSUMPTIONFLOW = {
  source: "consumption",
  target: "inverter",
  sourceHandle: "left",
  targetHandle: "right",
};
const HEATPUMPFLOW = {
  source: "heatpump",
  target: "consumption",
  sourceHandle: "left",
  targetHandle: "right",
};
const WALLBOXFLOW = {
  source: "wallbox",
  target: "consumption",
  sourceHandle: "left",
  targetHandle: "right",
};
const HOUSEHOLDFLOW = {
  source: "household",
  target: "consumption",
  sourceHandle: "left",
  targetHandle: "right",
};

const getEdgeFromFlow = (flow, flowVal) => {
  const val = round(flowVal > 0 ? flowVal : -flowVal, 1);
  return {
    id: flow.source,
    type: "flow-edge",
    label: val,
    source: flowVal > 0 ? flow.source : flow.target,
    target: flowVal > 0 ? flow.target : flow.source,
    sourceHandle: flowVal > 0 ? flow.sourceHandle : flow.targetHandle,
    targetHandle: flowVal > 0 ? flow.targetHandle : flow.sourceHandle,
    data: {
      value: val,
      color: val !== 0 ? "#34a894" : "#474b4e",
    },
    animated: false,
  };
};

export const getEdges = (flowData, consumptionSplitter) => {
  const flow = flowData.flow;
  const edges = [
    flow.to_grid != null ? getEdgeFromFlow(GRIDFLOW, -flow.to_grid) : null,
    flow.to_battery != null
      ? getEdgeFromFlow(BATTERYFLOW, -flow.to_battery)
      : null,
    flow.pv != null ? getEdgeFromFlow(PVFLOW, flow.pv) : null,
    getEdgeFromFlow(
      CONSUMPTIONFLOW,
      -((flow.household || 0) + (flow.heatpump || 0) + (flow.wallbox || 0))
    ),
    flow.household != null && consumptionSplitter
      ? getEdgeFromFlow(HOUSEHOLDFLOW, -flow.household)
      : null,
    flow.heatpump != null && consumptionSplitter
      ? getEdgeFromFlow(HEATPUMPFLOW, -flow.heatpump)
      : null,
    flow.wallbox != null && consumptionSplitter
      ? getEdgeFromFlow(WALLBOXFLOW, -flow.wallbox)
      : null,
  ]
    .filter((x) => x !== null)
    .sort((a, b) => a.data.value - b.data.value);
  return edges;
};

export const edgeTypes = {
  "custom-edge": Edge,
  "flow-edge": FlowEdge,
};
