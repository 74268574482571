import React, { Fragment, useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import LoadingPage from "../../react_utils/LoadingPage";
import { API_URL_cLOCATION } from "../../urls";
import PageInstructions from "./help/PageInstructions";
import TabView from "../../react_utils/TabView";
import ImpulsClientInstructions from "./help/ImpulsClientInstructions";

export default function Help({ session }) {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    session.backendClient
      .get(API_URL_cLOCATION, { params: { nested: true } })
      .then((res) => {
        setLocation(res.data);
      });
  }, []);

  if (!location) return <LoadingPage />;
  return (
    <Fragment>
      <TabView
        components={[
          {
            title: "Webseite",
            getChild: () => (
              <PageInstructions location={location} session={session} />
            ),
          },
          {
            title: "Energieimpuls",
            getChild: () => (
              <ImpulsClientInstructions location={location} session={session} />
            ),
          },
        ]}
      />
    </Fragment>
  );
}

Help.propTypes = {
  session: PropTypes.object,
};
