import React from "react";

import { PropTypes } from "prop-types";

import ModifyNameModal from "../common/ModifyNameModal";

export default function HeatpumpNameModal({
  heatpump,
  editHeatpumpName,
  resetParent,
  modalProps,
  session,
}) {
  return (
    <ModifyNameModal
      initial={heatpump.name}
      editName={(name) => editHeatpumpName(heatpump, name)}
      resetParent={resetParent}
      modalProps={modalProps}
    />
  );
}

HeatpumpNameModal.propTypes = {
  editHeatpumpName: PropTypes.func,
  session: PropTypes.object,
};
