import React from "react";
import PropTypes from "prop-types";

import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import { EmptyTableRow, TableHeaderCell, TableRow } from "./tableutils";

export default function Table({
  columns,
  rows,
  collapsible = false,
  emptyTableText = null,
}) {
  // columns: { name, key, props={} }
  //    headerprops (e.g. align="right/left")
  //    rowprops
  return (
    <MuiTableContainer>
      <MuiTable
        aria-label="collapsible table"
        sx={{ backgroundColor: "transparent" }}
      >
        <MuiTableHead>
          <MuiTableRow>
            {collapsible ? <MuiTableCell /> : null}
            {columns.map((col, cIdx) => (
              <TableHeaderCell key={`table-header-col${cIdx}`} column={col} />
            ))}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {(!rows || rows.length === 0) && emptyTableText && (
            <EmptyTableRow
              columns={columns}
              emptyTableText={emptyTableText}
              collapsible={collapsible}
            />
          )}
          {rows &&
            rows.map((row, rIdx) => (
              <TableRow
                key={`table-row${rIdx}`}
                columns={columns}
                row={row}
                collapsible={collapsible}
              />
            ))}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  collapsible: PropTypes.bool,
  // pagination: PropTypes.object
};
