import { Handle, Position } from "reactflow";
import { Typography } from "@mui/material";
import { getNodeStyle } from "./nodestyle";

export default function GridNode({ data }) {
  return (
    <div style={getNodeStyle(data.sizePx)}>
      <div className="text-center">
        <img src={"/icons/grid.svg"} alt="Grid Icon" height={"50px"} />
        <Typography variant="subtitle2" color="primary">
          Netz
        </Typography>
      </div>
      <Handle
        type="source"
        id="top"
        className="handletop"
        position={Position.Top}
      />
      <Handle
        type="target"
        id="top"
        className="handletop"
        position={Position.Top}
      />
    </div>
  );
}
