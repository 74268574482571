import HouseNode from "./HouseNode";
import BatteryNode from "./BatteryNode";
import GridNode from "./GridNode";
import SolarNode from "./SolarNode";
import ConsumptionNode from "./ConsumptionNode";
import HouseholdNode from "./HouseholdNode";
import WallboxNode from "./WallboxNode";
import HeatpumpNode from "./HeatpumpNode";

export const getNodes = (flowData, consumptionSplitter) => {
  const flow = flowData.flow;

  const sizePx = 100;
  const sizePxHouse = 130;
  const emptyNodeHeight = 42;
  const sizeConsumption = consumptionSplitter ? 0 : sizePx;

  // col1 = battery
  const col1Center = 90;
  // col2 = inverter, pv, grid
  const col2Center = 265;
  // col3 = consumption node
  const col3Center = 440;
  // col4 = household, wallbox, heatpump
  const col4Center = 520;
  // col1
  const xpos_battery = col1Center - sizePx / 2;
  // col2
  const xpos_inverter = col2Center - sizePxHouse / 2;
  const xpos_pv_grid = col2Center - sizePx / 2;
  // col3
  const xpos_consumption = col3Center - sizePx / 2;
  // col4
  const xpos_house_wall_heat = col4Center - sizePx / 2;

  // row 1 = PV / wallbox
  const row1Center = 50;
  // row 2 = battery / household / inverter
  const row2Center = 215;
  // row 2 = grid/ heatpump
  const row3Center = 380;
  // row1
  const ypos_pv_wall = row1Center - sizePx / 2;
  // row2
  const ypos_inverter = row2Center - sizePxHouse / 2;
  const ypos_bat_house = row2Center - sizePx / 2;
  let ypos_consumption = row2Center - (sizeConsumption || emptyNodeHeight) / 2;
  if (consumptionSplitter) ypos_consumption = ypos_consumption - 2;
  // row3
  const ypos_grid_heat = row3Center - sizePx / 2;

  const nodes = [
    flow.to_battery != null
      ? {
          id: "battery",
          data: {
            label: "Batterie",
            soc: flowData.state.battery_soc,
            sizePx: sizePx,
          },
          position: { x: xpos_battery, y: ypos_bat_house },
          type: "battery-node",
        }
      : null,
    {
      id: "inverter",
      data: { label: "Haus", sizePx: sizePxHouse },
      position: { x: xpos_inverter, y: ypos_inverter },
      type: "house-node",
    },
    flow.pv != null
      ? {
          id: "solar",
          data: { label: "PV", sizePx: sizePx },
          position: { x: xpos_pv_grid, y: ypos_pv_wall },
          type: "solar-node",
        }
      : null,
    flow.to_grid != null
      ? {
          id: "grid",
          data: { label: "Netz", sizePx: sizePx },
          position: { x: xpos_pv_grid, y: ypos_grid_heat },
          type: "grid-node",
        }
      : null,
    {
      id: "consumption",
      data: { label: "", sizePx: sizeConsumption },
      position: { x: xpos_consumption, y: ypos_consumption },
      type: "consumption-node",
    },
    flow.household != null && consumptionSplitter
      ? {
          id: "household",
          data: { label: "Haushalt", sizePx: sizePx },
          position: { x: xpos_house_wall_heat, y: ypos_bat_house },
          type: "household-node",
        }
      : null,
    flow.wallbox != null && consumptionSplitter
      ? {
          id: "wallbox",
          data: { label: "Wallbox", sizePx: sizePx },
          position: { x: xpos_house_wall_heat, y: ypos_pv_wall },
          type: "wallbox-node",
        }
      : null,
    flow.heatpump != null && consumptionSplitter
      ? {
          id: "heatpump",
          data: { label: "Wärmepumpe", sizePx: sizePx },
          position: { x: xpos_house_wall_heat, y: ypos_grid_heat },
          type: "heatpump-node",
        }
      : null,
  ].filter((x) => x != null);
  return nodes;
};

export const nodeTypes = {
  "house-node": HouseNode,
  "battery-node": BatteryNode,
  "grid-node": GridNode,
  "solar-node": SolarNode,
  "consumption-node": ConsumptionNode,
  "household-node": HouseholdNode,
  "heatpump-node": HeatpumpNode,
  "wallbox-node": WallboxNode,
};
