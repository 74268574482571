import React, { Fragment, useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import { Grid } from "@mui/material";

import LoadingPage from "../../../react_utils/LoadingPage";
import { API_URL_HEATPUMPSGREADYHISTORY } from "../../../urls";
import SgReadyHistoryTable from "../heatpump/SgReadyHistoryTable";

export default function SgReadyHistoryTab({ location, session }) {
  const [sgReadyHistory, setSgReadyHistory] = useState(null);

  useEffect(() => {
    if (!location) return;
    const params = {
      heatpump__location: location.id,
    };
    session.backendClient
      .get(API_URL_HEATPUMPSGREADYHISTORY, { params })
      .then((res) => {
        setSgReadyHistory(res.data);
      });
  }, [location]);

  if (sgReadyHistory === null) return <LoadingPage />;

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          justifyContent="center"
          alignItems="center"
        >
          <SgReadyHistoryTable
            session={session}
            sgReadyHistory={sgReadyHistory}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

SgReadyHistoryTab.propTypes = {
  session: PropTypes.object,
};
