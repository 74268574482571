import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { Grid } from "@mui/material";

import EnergyFlow from "../flow/EnergyFlow";

import {
  API_URL_FLOW,
  API_URL_HEATPUMPSETPOINT,
  API_URL_WALLBOX,
  API_URL_WALLBOXSETPOINT,
  API_URL_HEATPUMP,
} from "../../../urls";
import HeatpumpCard from "../heatpump/HeatpumpCard";
import WallboxCard from "../wallbox/WallboxCard";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import { time2String } from "../../../react_utils/utils";

export default function FlowTab({ location, session }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });
  const [flowData, setFlowData] = useState(null);
  const [wallboxSetPoints, setWallboxSetPoints] = useState(null);
  const [heatpumpSetPoints, setHeatpumpSetPoints] = useState(null);


  const resetState = () => {
    loadData();
  };

  const updateFlow = async () => {
    if (!location) return;
    const params = {
      location: location.id,
    };
    session.backendClient.get(API_URL_FLOW, { params }).then((res) => {
      setFlowData(res.data);
    });
  };

  const updateWallboxStates = () => {
    if (!location) return location;
    session.backendClient
      .get(API_URL_WALLBOXSETPOINT, {
        params: { nested: true, wallbox__location: location.id },
      })
      .then((res) => {
        setWallboxSetPoints(res.data);
      });
  };

  const updateHeatpumpStates = () => {
    if (!location) return location;
    session.backendClient
      .get(API_URL_HEATPUMPSETPOINT, {
        params: { nested: true, heatpump__location: location.id },
      })
      .then((res) => {
        setHeatpumpSetPoints(res.data);
      });
  };

  const loadData = () => {
    updateFlow();
    updateWallboxStates();
    updateHeatpumpStates();
  };

  useEffect(() => {
    loadData();
    const interval = setInterval(() => {
      loadData();
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [location]);

  const editWallboxName = (wallbox, name) => {
    return session.backendClient.put(API_URL_WALLBOX + wallbox.id, { name });
  };

  const editHeatpumpName = (heatpump, name) => {
    return session.backendClient.put(API_URL_HEATPUMP + heatpump.id, { name });
  };

  const editHeatpumpTime = (heatpump, lower, higher) => {
    return session.backendClient.put(API_URL_HEATPUMP + heatpump.id, {
      sg_ready_start_threshold: time2String(lower),
      sg_ready_end_threshold: time2String(higher),
    });
  };

  const handleWallboxLockChange = (setpoint, locked) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { locked: locked }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "Die Wallbox wurde erfolgreich " +
            (locked ? "gesperrt!" : "enstperrt!"),
          open: true,
        }))
      );
  };

  const handleWallboxSurplusChange = (setpoint, surplus_charging) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { surplus_charging: surplus_charging }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "PV-Überschussladen wurde erfolgreich " +
            (surplus_charging ? "aktiviert!" : "deaktiviert!"),
          open: true,
        }))
      );
  };

  const handleWallboxHybridChange = (setpoint, hybrid_charging_current) => {
    const showSnackbar =
      !!setpoint.hybrid_charging_current !== !!hybrid_charging_current;
    if (showSnackbar) setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { hybrid_charging_current }
    );
    return promise
      .then((res) =>
        setWallboxSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() => {
        if (showSnackbar)
          setSnackbar((s) => ({
            ...s,
            msg:
              "PV-Hybridladen wurde erfolgreich " +
              (hybrid_charging_current ? "aktiviert!" : "deaktiviert!"),
            open: true,
          }));
      });
  };

  const handleHeatpumpSgReadyChange = (setpoint, sg_ready) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_HEATPUMPSETPOINT + setpoint.id,
      { sg_ready: sg_ready }
    );
    return promise
      .then((res) =>
        setHeatpumpSetPoints((setPoints) => {
          setPoints.forEach((sp) => {
            if (sp.id === setpoint.id) Object.assign(sp, res.data);
          });
          return [...setPoints];
        })
      )
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "PV-Einschaltbefehl wurde erfolgreich " +
            (sg_ready ? "aktiviert!" : "deaktiviert!"),
          open: true,
        }))
      );
  };

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          justifyContent="center"
          alignItems="center"
        >
          {flowData && (
            <EnergyFlow flowheight={{ xs: "400px", md: "600px" }} flowData={flowData} />
          )}
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          {(heatpumpSetPoints || []).map((heatpumpSetPoint) => (
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              item
              justifyContent="center"
              alignItems="center"
              key={`heatpump-card-wrapper-${heatpumpSetPoint.heatpump}`}
            >
              <HeatpumpCard
                editHeatpumpTime={
                  session.user.is_superuser ? editHeatpumpTime : null
                }
                editHeatpumpName={
                  session.user.is_superuser &&
                  heatpumpSetPoints &&
                  heatpumpSetPoints.length > 1
                    ? editHeatpumpName
                    : null
                }
                heatpumpSetPoint={heatpumpSetPoint}
                setHeatpumpSgReady={
                  session.user.is_superuser && handleHeatpumpSgReadyChange
                }
                resetParent={resetState}
                infoText={false}
                session={session}
              />
            </Grid>
          ))}
          {(wallboxSetPoints || []).map((wallboxSetPoint) => (
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              item
              justifyContent="center"
              alignItems="center"
              key={`wallbox-card-wrapper-${wallboxSetPoint.wallbox}`}
            >
              <WallboxCard
                editWallboxName={
                  session.user.is_superuser &&
                  wallboxSetPoints &&
                  wallboxSetPoints.length > 1
                    ? editWallboxName
                    : null
                }
                wallboxSetPoint={wallboxSetPoint}
                setWallboxLocked={
                  session.user.is_superuser && handleWallboxLockChange
                }
                setWallboxSurplusCharging={
                  session.user.is_superuser && handleWallboxSurplusChange
                }
                setWallboxHybridCharging={
                  session.user.is_superuser && handleWallboxHybridChange
                }
                infoText={false}
                resetParent={resetState}
                session={session}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

FlowTab.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object,
};
