import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import { Container, Typography, Stack } from "@mui/material";

import Button from "@mui/material/Button";
import { API_URL_FORGOT_PASSWORD } from "../../urls";
import { componentStyles } from "../../react_utils/StyledElements";
import CharsInput from "../../react_utils/fields/CharsInput";

export default function ForgotPassword({ session }) {
  const [usernameOrEmail, setUsernameOrEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    session.backendClient
      .post(API_URL_FORGOT_PASSWORD, { username_or_email: usernameOrEmail })
      .then((res) => {
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        console.error(error);
        setError(
          "Bitte geben Sie eine gültige E-Mail oder einen gültigen Nutzernamen ein."
        );
      });
  };

  return (
    <Fragment>
      <Container component="main" maxWidth="xs" style={{ marginTop: 20 }}>
        <form sx={componentStyles.form} noValidate onSubmit={handleSubmit}>
          <Stack direction="column" spacing={3}>
            <Typography
              color="primary"
              component="h1"
              fontSize="h5.fontSize"
              align="center"
            >
              Neue Zugangsdaten anfordern
            </Typography>
            <CharsInput
              fullWidth={true}
              placeholder="Nutzername oder E-Mail"
              onChange={(v) => {
                setError(false);
                setSuccess(false);
                setUsernameOrEmail(v);
              }}
            />
            {success ? (
              <Typography color="green" component="h3" fontSize="h6.fontSize">
                Eine E-Mail mit neuen Zugangsdaten wurde versendet!
              </Typography>
            ) : null}
            {error ? (
              <Typography color="error" component="h3" fontSize="h6.fontSize">
                {" "}
                {error}{" "}
              </Typography>
            ) : null}
            <Button
              type="submit"
              variant="outlined"
              style={{ color: "#efefef", borderColor: "#efefef" }}
              fullWidth
            >
              Zugangsdaten anfordern
            </Button>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                style={{ color: "#efefef", borderColor: "#efefef" }}
                onClick={() => {
                  history.push("/");
                }}
                fullWidth
              >
                Zurück zur Startseite
              </Button>
            </Link>
          </Stack>
        </form>
        {/* </div> */}
      </Container>
    </Fragment>
  );
}
