import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ReactFlow, { Controls, Background } from "reactflow";

export default function Flow({
  flowheight,
  flowwidth,
  nodes,
  edges,
  edgeTypes,
  nodeTypes,
}) {
  const [flowInstance, setFlowInstance] = useState(null);

  const onLoad = (rf) => {
    setFlowInstance(rf);
  };

  useEffect(() => {
    if (flowInstance) {
      flowInstance.fitView();
    }
  }, [flowInstance, nodes, edges]);

  return (
    <Box sx={{ height: flowheight, width: flowwidth }}>
      <ReactFlow
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        onInit={onLoad}
        edgesUpdatable={false}
        edgesFocusable={false}
        nodesDraggable={false}
        nodesConnectable={false}
        nodesFocusable={false}
        draggable={false}
        elementsSelectable={false}
        preventScrolling={false}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </Box>
  );
}
