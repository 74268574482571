import React, { Fragment } from "react";

import { PropTypes } from "prop-types";

import { Box, Divider, Stack, Typography } from "@mui/material";

import {
  CheckBoxField,
  CustomField,
  DateField,
  DropdownField,
  MultiSelectField,
  NumberInputField,
  SubtitleField,
  ToggleField,
  CharsInputField,
  GoogleAddressInputField,
} from "./CustomFormFields";
import { checkIfValueIsEmpty, getEmptyFieldsError } from "../utils";

export const getOptionalKeysFromTemplate = (template, form) => {
  return template
    .filter((field) => field.key)
    .filter(
      (field) => field.optional || (field.condition && !field.condition(form))
    )
    .map((f) => f.key);
};

export const getCheckIfEmptyFuncFromTemplate = (template, form) => {
  const optionalFields = template
    .filter((field) => field.key)
    .filter(
      (field) => field.optional || (field.condition && !field.condition(form))
    );
  const fields = template.filter((f) => f.key).filter((f) => f.isEmpty);
  return (key, val) => {
    if (optionalFields.find((f) => f.key === key)) return false;
    const field = fields.find((f) => f.key === key);
    if (field) return field.isEmpty(val);
    return checkIfValueIsEmpty(val);
  };
};

export const getEmptyFieldsErrorFromTemplate = (
  template,
  form,
  defaultForm
) => {
  const checkIfEmpty = getCheckIfEmptyFuncFromTemplate(template, form);
  return getEmptyFieldsError(form, defaultForm, [], checkIfEmpty);
};

const getFieldHtml = (
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  session,
  wrap = true
) => {
  switch (field.type) {
    case "toggle":
      return (
        <ToggleField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "input_number":
      return (
        <NumberInputField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "input_chars":
      return (
        <CharsInputField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "address":
      return (
        <GoogleAddressInputField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "dropdown":
      return (
        <DropdownField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "multiselect":
      return (
        <MultiSelectField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "date":
      return (
        <DateField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "checkbox":
      return (
        <CheckBoxField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "custom":
      return (
        <CustomField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    case "subtitle":
      return (
        <SubtitleField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
          session={session}
        />
      );
    default:
      return null;
  }
};

export default function CustomForm({
  title,
  template,
  form,
  setForm,
  defaultForm,
  showMissingFields,
  session,
}) {
  const elements = template
    .filter((field) => !field.condition || field.condition(form))
    .filter((field) => !field.hide)
    .map((field) => {
      const html = getFieldHtml(
        field,
        form,
        setForm,
        defaultForm,
        template,
        showMissingFields,
        session,
        !field.htmlWrapper
      );
      return field.htmlWrapper ? field.htmlWrapper(html, form) : html;
    })
    .filter((i) => i);
  return (
    <Box style={{ marginTop: "10px" }}>
      {title ? (
        <>
          <Typography fontSize="h4.fontSize" color="primary">
            {title}
          </Typography>
          <Divider sx={{ bgcolor: "primary.main", marginTop: 2 }} />
        </>
      ) : null}
      <Stack direction="column" spacing={2}>
        {elements.map((element, eidx) => (
          <Fragment key={`custom-form-element-${eidx}`}>{element}</Fragment>
        ))}
      </Stack>
    </Box>
  );
}

CustomForm.propTypes = {
  title: PropTypes.string,
  template: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object,
  setForm: PropTypes.func,
  defaultForm: PropTypes.object,
  showMissingFields: PropTypes.bool,
};
