import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

import { getGrowattAccountFormTemplate } from "./GrowattAccountForm";

import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";

import { API_URL_GROWATTACCOUNT, API_URL_LOCATION } from "../../../urls";

import { CustomButton } from "../../../react_utils/StyledElements";
import CustomForm, {
  getEmptyFieldsErrorFromTemplate,
} from "../../../react_utils/form/CustomForm";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";

export const emptyAccountForm = {
  username: "",
  password: "",
  location: null,
};

export default function GrowattAccountFormModal({
  growattAccount,
  location = null,
  isOpen,
  setIsOpen,
  resetParent,
  getOpenButton,
  session,
}) {
  const [growattAccountForm, setGrowattAccountForm] = useState({
    ...emptyAccountForm,
  });
  const [locations, setLocations] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  const growattAccountRef = useRef(null);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setGrowattAccountForm(emptyAccountForm);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    growattAccountRef.current = null;
    setGrowattAccountForm({
      ...(growattAccount || emptyAccountForm),
      ...(location ? { location: location.id } : {}),
    });
    if (!location)
      session.backendClient
        .get(API_URL_LOCATION)
        .then((res) => setLocations(res.data));
    else setLocations([location]);
  };

  useEffect(() => {
    loadData();
  }, [growattAccount]);

  useEffect(() => {
    if (!growattAccount) loadData();
  }, [session.user]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton === null) return null;
    if (getOpenButton) return getOpenButton(toggle);
    if (growattAccount) {
      return (
        <CustomButton onClick={toggle} icon="info">
          Growattkonto
        </CustomButton>
      );
    }
    return (
      <CustomButton
        onClick={toggle}
        style={{ maxWidth: "300px" }}
        icon="account"
        id="add_growattaccount_btn"
      >
        Konto hinzufügen
      </CustomButton>
    );
  };

  const submit = async (growattAccountForm) => {
    return growattAccount || growattAccountRef.current
      ? session.backendClient.put(
          API_URL_GROWATTACCOUNT + growattAccount?.id ||
            growattAccountRef.current.id,
          growattAccountForm
        )
      : session.backendClient
          .post(API_URL_GROWATTACCOUNT, growattAccountForm)
          .then((res) => {
            growattAccountRef.current = res.data;
            return res;
          });
  };

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(
      template,
      growattAccountForm,
      emptyAccountForm
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      console.error(emptyFieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return submit(growattAccountForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setSnackbar((s) => ({
          ...s,
          open: true,
          msg:
            growattAccount || growattAccountRef.current
              ? "Konto gespeichert."
              : "Konto erfolgreich erstellt.",
        }));
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "growattAccount:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError:
            error?.response?.status === 400
              ? "Dieses Growattkonto konnte nicht gefunden werden."
              : error?.response?.status === 409
              ? "Dieses Growattkonto ist bereits auf dieser Webseite registriert."
              : true,
          inProgress: false,
        });
      });
  };

  const onDelete = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return session.backendClient
      .delete(API_URL_GROWATTACCOUNT + growattAccount.id)
      .then((res) => {
        resetParent();
        onSuccess();
        setSnackbar((s) => ({
          ...s,
          open: true,
          msg: "Konto erfolgreich gelöscht.",
        }));
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "growattAccount:onDelete"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_growattaccount_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onDelete={growattAccount ? () => onDelete(toggle) : null}
        saveBtnLabel={!!growattAccount ? "Speichern" : "Erstellen"}
      />
    );
  };

  const template = getGrowattAccountFormTemplate(
    setGrowattAccountForm,
    locations,
    !!growattAccount,
    session
  );

  return (
    <>
      <CustomModal
        getOpenButton={_getOpenButton}
        title="Growattkonto"
        getFooter={getFooter}
        onToggle={onToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <CustomForm
          template={template}
          form={growattAccountForm}
          setForm={setGrowattAccountForm}
          defaultForm={emptyAccountForm}
          showMissingFields={loadingElements.showMissingFields}
          session={session}
        />
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </>
  );
}

GrowattAccountFormModal.propTypes = {
  growattAccount: PropTypes.object,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
