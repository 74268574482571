import React from "react";

import { PropTypes } from "prop-types";
import Customers from "./users/Customers";
import TabView from "../../react_utils/TabView";
import Employees from "./users/Employees";


export default function Users({ session }) {
  if (!session.user.is_superuser) return <Customers session={session} />;
  return (
    <TabView
      components={[
        {
          title: "Kunden",
          getChild: () => <Customers session={session} />,
        },
        {
          title: "Mitarbeiter",
          getChild: () => <Employees session={session} />,
        },
      ]}
    />
  );
}

Users.propTypes = {
  session: PropTypes.object,
};
