import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";

import CharsInput from "../../../react_utils/fields/CharsInput";
import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";

export default function ModifyNameModal({
  initial,
  editName,
  resetParent,
  modalProps = {},
  session,
}) {
  const [newName, setNewName] = useState(initial || "");

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  const onToggle = (isOpen) => {
    if (isOpen) setNewName(initial || "");
    if (modalProps.onToggle) modalProps.onToggle(isOpen);
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        disabled={!newName}
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={"Speichern"}
      />
    );
  };

  const onSubmit = async (onSuccess) => {
    return submit(newName)
      .then((res) => {
        resetParent && resetParent();
        onSuccess();
        setSnackbar((s) => ({ ...s, msg: "Name geändert.", open: true }));
      })
      .catch((error) => {
        console.error(
          'Error in "ModifyNameModal:onSubmit"',
          error,
          error.stack
        );
      });
  };

  const submit = async (newName) => {
    return editName(newName);
  };

  return (
    <Fragment>
      <CustomModal
        size="sm"
        title={"Name ändern"}
        getFooter={getFooter}
        {...modalProps}
        onToggle={onToggle}
      >
        <CharsInput
          label="Name"
          onChange={(v) => setNewName(v)}
          value={newName}
        />
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

ModifyNameModal.propTypes = {
  initial: PropTypes.string,
  editName: PropTypes.func,
  session: PropTypes.object,
};
