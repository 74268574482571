import React from "react";

import { Typography, Backdrop, CircularProgress, Stack } from "@mui/material";

export default function LoadingPage() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Stack direction="row" spacing={4}>
        <CircularProgress color="inherit" />
        <Typography fontSize="h4.fontSize" color="primary">
          Wird geladen...
        </Typography>
      </Stack>
    </Backdrop>
  );
}
