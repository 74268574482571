import React, { Fragment, useEffect, useState } from "react";
import {
  addNDays,
  date2FormattedString,
  date2String,
  datetime2FormattedString,
  getDateRange,
  string2Datetime,
} from "../../../react_utils/utils";
import DatePicker from "../../../react_utils/DatePicker";
import { Stack, Typography } from "@mui/material";
import ExcelExport from "../../../react_utils/ExcelExport";
import { getBatterySoCFlow, getPowerFlow } from "./flowhistoryutils";

const MAXDAYS = 2 * 7;

export default function HourlyFlowDownloader({
  defaultDate,
  getSyncedFlow,
  session,
}) {
  const [exportStartDate, setExportStartDate] = useState(defaultDate);
  const [exportEndDate, setExportEndDate] = useState(defaultDate);

  useEffect(() => {
    if (exportStartDate > exportEndDate) setExportEndDate(exportStartDate);
  }, [exportStartDate]);

  useEffect(() => {
    setExportStartDate(defaultDate);
    setExportEndDate(defaultDate);
  }, [defaultDate]);

  const getExcelData = async () => {
    const dates = getDateRange(exportStartDate, exportEndDate);
    return Promise.all(
      dates.map((date) =>
        getSyncedFlow(date).then((flow) => {
          return createExcelSheet(date, flow);
        })
      )
    );
  };

  const createExcelSheet = (date, flow) => {
    const powerFlow = { ...getPowerFlow(flow), ...getBatterySoCFlow(flow) };
    const header = ["Zeit", ...Object.values(powerFlow)];
    const rows = flow.time.map((t) => [
      datetime2FormattedString(string2Datetime(t)),
    ]);
    Object.keys(powerFlow).map((key) => {
      flow[key].forEach((val, idx) => rows[idx].push(val));
    });
    return { name: date2FormattedString(date), data: [header, ...rows] };
  };

  return (
    <Fragment>
      <Typography sx={{ marginBottom: "20px" }}>
        Historie als Exceldatei exportieren:
      </Typography>
      <Stack direction="row" spacing={2}>
        <DatePicker
          date={exportStartDate}
          setDate={setExportStartDate}
          label={"von"}
        />
        {exportStartDate && (
          <DatePicker
            date={exportEndDate}
            setDate={setExportEndDate}
            minDate={exportStartDate}
            maxDate={addNDays(exportStartDate, MAXDAYS)}
            label={"bis"}
          />
        )}
        <ExcelExport
          exporter={getExcelData}
          fileName={
            !(exportStartDate && exportEndDate)
              ? `Energiefluesse`
              : exportStartDate == exportEndDate
              ? `Energiefluesse-${date2String(exportStartDate)}`
              : `Energiefluesse-${date2String(exportStartDate)}-${date2String(
                  exportEndDate
                )}`
          }
          disabled={
            !(
              exportStartDate &&
              exportEndDate &&
              exportEndDate >= exportStartDate &&
              addNDays(exportStartDate, MAXDAYS) >= exportEndDate
            )
          }
        />
      </Stack>
      {!(addNDays(exportStartDate, MAXDAYS) >= exportEndDate) && (
        <Typography color="error">{`Daten von maximal ${MAXDAYS} Tagen können gleichzeitig exportiert werden.`}</Typography>
      )}
    </Fragment>
  );
}
