import { Handle, Position } from "reactflow";
import { getNodeStyle } from "./nodestyle";
import { Typography } from "@mui/material";

export default function ConsumptionNode({ data }) {
  return (
    <div style={getNodeStyle(data.sizePx)}>
      {data.sizePx !== 0 ? (
        <div className="text-center">
          <img
            src={"/icons/household.svg"}
            alt="Household Icon"
            height={"50px"}
          />
          <Typography variant="subtitle2" color="primary">
            Last
          </Typography>
        </div>
      ) : (
        <div>
          <label>{data?.label}</label>
        </div>
      )}
      <Handle
        type="source"
        id="left"
        className="handleleft"
        position={Position.Left}
      />
      <Handle
        type="target"
        id="left"
        className="handleleft"
        position={Position.Left}
      />
      <Handle
        type="source"
        id="right"
        className="handleright"
        position={Position.Right}
      />
      <Handle
        type="target"
        id="right"
        className="handleright"
        position={Position.Right}
      />
    </div>
  );
}
