import React from "react";
import { PropTypes } from "prop-types";

import dayjs from "dayjs";
import "dayjs/locale/de";

import { IconButton } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClearIcon, DesktopTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { popFromObj } from "./utils";
import { inputStyles } from "./StyledElements";

export default function TimePicker({
  time,
  setTime,
  minTime,
  maxTime,
  label = "Zeit",
  showClearIcon = true,
  clearDisabled = false,
  ...props
}) {
  const propsSx = popFromObj(props, "sx") || {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DesktopTimePicker
        label={label}
        value={dayjs(time)}
        minTime={minTime && dayjs(minTime)}
        maxTime={maxTime && dayjs(maxTime)}
        onChange={(v) => {
          const _date = v.toDate();
          _date.setFullYear(1900);
          _date.setMonth(0);
          _date.setDate(1);
          setTime(_date);
        }}
        slots={{
          openPickerIcon: () => null, // Disable the clock icon
        }}
        slotProps={{
          textField: {
            variant: "standard",
            InputProps: {
              ...(showClearIcon
                ? {
                    endAdornment: (
                      <IconButton
                        onClick={() => setTime(null)}
                        disabled={clearDisabled}
                      >
                        <ClearIcon
                          fontSize="small"
                          color={clearDisabled ? "disabled" : "primary"}
                        />
                      </IconButton>
                    ),
                  }
                : {}),
            },
            ...(time === null ? { error: false } : {}),
          },
        }}
        sx={{
          ...inputStyles.inputField,
          ...propsSx,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}

TimePicker.propTypes = {
  session: PropTypes.object,
};
