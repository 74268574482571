import React from "react";

import { PropTypes } from "prop-types";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TimeScale,
  Filler,
} from "chart.js";
import 'chartjs-adapter-luxon';
// import 'chartjs-adapter-date-fns'; // Or 'chartjs-adapter-moment'
import { Bar, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import { isMobileOnly } from "react-device-detect";

import { Box, Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin,
  TimeScale,
  Filler
);

export default function CustomPlot({
  title,
  datasets,
  ticks,
  xlabel,
  ylabel,
  type,
  stacked = false,
  height = { xs: "350px", md: "500px", lg: "500px", xl: "700px" },
  xTickOptions = {},
  pluginOptions = {},
  xScaleOptions = {},
  axRanges = {},
  additionalOptions = {},
}) {
  ChartJS.defaults.color = "#fff";

  const titleFontsize = isMobileOnly ? 12 : 18;
  const legendFontsize = isMobileOnly ? 12 : 16;

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: { font: { size: legendFontsize } },
      },
      tooltip: {
        footerFont: { weight: "normal" },
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
          // footer: function (context) {
          //   if (additionalData) {
          //     const additionalLabel = additionalData[0].label || ''
          //     const additionalInfo = additionalData[0].data[context[0].dataIndex]
          //     return `${additionalLabel}: ${additionalInfo}`
          //   }
          // },
        },
      },
      ...pluginOptions,
    },
    scales: {
      y: {
        stacked,
        ticks: { font: { size: legendFontsize } },
        grid: { color: "#323436" },
        title: {
          display: true,
          text: ylabel,
          font: { size: legendFontsize },
        },
        min: axRanges.minY,
        max: axRanges.maxY,
      },
      x: {
        stacked,
        ticks: { font: { size: legendFontsize }, ...xTickOptions },
        grid: { color: "#323436" },
        title: {
          display: true,
          text: xlabel,
          font: { size: legendFontsize },
        },
        ...xScaleOptions,
      },
    },
  };

  options = { ...options, ...additionalOptions };

  const data = {
    datasets,
  };

  if (ticks) data.labels = ticks


  return (
    <Box sx={{ height: height }}>
      <div className="text-center">
        <Typography fontSize={titleFontsize} fontWeight={"bold"}>
          {title}
        </Typography>
      </div>
      {type === "bar" ? (
        <Bar options={options} data={data} height={"100%"} />
      ) : (
        <Line options={options} data={data} height={"100%"} />
      )}
    </Box>
  );
}

CustomPlot.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  datasets: PropTypes.arrayOf(PropTypes.object),
  additionalData: PropTypes.arrayOf(PropTypes.object),
  ticks: PropTypes.arrayOf(PropTypes.string),
  xlabel: PropTypes.string,
  ylabel: PropTypes.string,
  type: PropTypes.string,
  stacked: PropTypes.bool,
};
